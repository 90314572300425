// makeRemainingPayment.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Call remaining payment method to complete the remaining amount on sale offer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Number} remainingAmount sale price - deposit amount is remaining amount
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function payCommitmentPayment(address, contract_address, contract_abi, token_address, assetData, remainingAmount, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        handleActiveStep(2);

        let amount = getCurrencyTypeDecimal(assetData?.feeTokenType, remainingAmount);

        const { request: payCommitmentPayment } = await prepareWriteContract({
            address: contract_address,
            abi: contract_abi,
            account: address,
            chainId: 6806,
            functionName: "makePayment",
            args: [
                assetData?.listing_id, // listing id 
                assetData?.blockchainOfferId, // investment id / offer id of blockchain
                token_address,
                amount // amount
            ]
        })

        const { hash } = await writeContract(payCommitmentPayment)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, amount, assetData?.asset_id, assetData)
                    handleModalClose();
                    handleActiveStep(-1)
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error in calling open fund complete commitments:', error);
        throw error; // propagate the error
    }
}

export { payCommitmentPayment };