import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CardHeader, Grid, Modal, Typography, Stack, IconButton, CircularProgress } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FileViewer from "react-file-viewer";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ArrowDropDown } from "@mui/icons-material";

export default function Templates({ selected_templates }) {
    const [showMore, setShowMore] = useState(false);

    const [view, setView] = useState(false);
    const [file, setFile] = useState("");
    const [type, setType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState([]);
    const [viewedTemplates, setViewedTemplates] = useState([]);
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState('');

    /**
   * open modal to display doc content
   * @param {Array} documentdata 
   * @returns {void}
   */
    const viewDoc = async (documentdata, mode, index) => {
        setCurrent(documentdata)
        setLoading(true);

        try {
            // Check if the PDF has been viewed before
            const isViewed = viewedTemplates?.filter((item) => item?.templateId === documentdata?.templateId);

            if (isViewed.length) {
                setFile(isViewed[0]?.templateUrl);
                setType('pdf');

                // PDF has been viewed before, no need to fetch again
                setLoading(false);
                setOpenModal(true);
                setView(true);
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/docusign/viewTemplate?templateId=${documentdata?.templateId}`);

            const templateBuffer = await response.arrayBuffer();
            const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
            const templateUrl = URL.createObjectURL(templateBlob);
            setFile(templateUrl);
            setType('pdf');

            // Mark the template as viewed and store in local state
            setViewedTemplates(prevViewedTemplates => [
                ...prevViewedTemplates,
                { templateId: documentdata.templateId, templateUrl }
            ]);

            setOpenModal(true);
            setView(true);
            setLoading(false);
            setCurrent('')
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }


    };

    useEffect(() => {
        setData(selected_templates);
    }, [selected_templates]);

    /**
     * Onclose function for Modal
     * @returns void
     */
    const handleClose = () => {
        setOpenModal(false);
        setView(false);
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };

    // Helper function to truncate a string and add an ellipsis if it's too long
    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + "...";
        }
        return str;
    };

    // handle show more button
    const handleShowMore = () => {
        setShowMore(true);
    }

    return (
        <>
            <Box className="pd-sidebar-documents" mb={3}>
                <Stack
                    className="pd-sidebar-heading"
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography className="title" component={"h4"}>
                        Agreement Default Templates
                    </Typography>

                </Stack>

                {/* Dynamically rendering documents with viewer  */}

                {data && data.length > 0 ? (<>
                    {
                        data?.map((val, ind) => {
                            if (ind < 5 || showMore) {
                                const subheaderText = val?.images?.original_name
                                    ? val.images.original_name
                                    : val.name;
                                const truncatedSubheader = truncateString(subheaderText, 18); // You can change 30 to your desired length
                                return (
                                    <Card className="pd-action-card" key={ind}>
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    aria-label="recipe"
                                                    className="bg-blue-900"
                                                    variant="square"
                                                    sx={{
                                                        width: "48px",
                                                        height: "48px",
                                                        borderRadius: "12px",
                                                    }}
                                                >
                                                    <AssignmentIcon />
                                                </Avatar>
                                            }
                                            action={
                                                <>
                                                    <Box className="btn-wrap">
                                                        <Stack
                                                            direction="row"
                                                            spacing={1.5}
                                                            alignItems={"center"}
                                                        >
                                                            {current === val && loading ?
                                                                <Box textAlign="center">
                                                                    <CircularProgress />
                                                                </Box>
                                                                :
                                                                <IconButton
                                                                    aria-label="View"
                                                                    size="large"
                                                                    className="square-icon-btn"
                                                                    onClick={() => viewDoc(val)}
                                                                >
                                                                    <RemoveRedEyeIcon />
                                                                </IconButton>
                                                            }
                                                        </Stack>
                                                    </Box>
                                                </>
                                            }
                                            title={truncatedSubheader}
                                            subheader={'Template'}
                                        />
                                    </Card>
                                );
                            } else {
                                return null
                            }
                        })
                    }
                    {(showMore === false && data?.length > 5) &&
                        <Stack flexDirection={'row'} justifyContent={'space-between'} mt={2}>
                            <Stack flexDirection={'row'} justifyContent={'space-around'}>
                                <Typography>More</Typography>
                            </Stack>
                            <Box sx={{ cursor: 'pointer' }} onClick={handleShowMore}>
                                <ArrowDropDown />
                            </Box>
                        </Stack>
                    }
                </>
                ) : (
                    <Typography textAlign={"center"}>
                        No templates available to view
                    </Typography>
                )}
            </Box>
            <Grid>
                {/* {view && !loading && (
                    <Modal
                        className="projectDocumentsModal"
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        fullWidth={true}
                    >
                        <Box sx={styleModal}>
                            <OverlayScrollbarsComponent
                                defer
                                style={{ width: "100%", height: "100%" }}
                            >
                                <Box className="raj">
                                    {<FileViewer fileType={type} filePath={file} />}
                                </Box>
                            </OverlayScrollbarsComponent>
                        </Box>
                    </Modal>
                )} */}
                {(view && !loading && type && file) && (
                    <Modal
                        className="projectDocumentsModal"
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        fullWidth={true}
                    >
                        <Box sx={styleModal}>
                            {/* <iframe
                title="PDF Viewer"
                src={file}
                style={{ width: "100%", height: "100%" }}
                className="pdf-viewer-modal"
              ></iframe> */}
                            <object
                                data={file}
                                type="application/pdf"
                                style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                            >
                                <p style={{ textAlign: 'center' }}>
                                    Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                                </p>
                            </object>
                        </Box>
                    </Modal>
                )}
            </Grid>
        </>
    );
}
