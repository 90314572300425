/**
 * @fileoverview This file contains all the constants used in the application.
 */

/**
 * The status of an offer. 
 * @readonly
 * @enum {number}
 */
export const OFFER_STATUS = {
    PENDING: 1,
    ACCEPTED: 2,
    REJECTED: 3,
    HOLD: 4,
    COMPLETED: 5,
    EXPIRED: 6,
    CANCELLED: 7,
    ADMIN_REJECTED: 8,
    RETURNED: 9,
    ADMIN_APPROVED: 10,
    PAYMENT_TIME_EXPIRED: 11
}

/**
 * The status of a transaction.
 * @readonly
 * @enum {number}
 */
export const TRANSACTION_STATUS = {
    PENDING: 1,
    SUCCESS: 2,
    FAILED: 3,
}

/**
 * Investment types. value are matched mapped from api.
 * @readonly
 * @enum {string}
 */
export const INVESTMENT_TYPE = {
    SALE: 'Sale',
    PARCEL: 'Parcelling',
    LOAN: 'Loan',
    FRACTION: 'Fractionalize',
}

/**
 * Investment types. value are matched mapped from api.
 * @readonly
 * @enum {number}
 */
export const INVESTMENT_TYPE_ID = {
    SALE: 1,
    LOAN: 2,
    PARCEL: 3,
    FRACTION: 4,
}

/**
 * Category types. value are matched mapped from api,
 * @readonly
 * @enum {string}
 */
export const CATEGORY_TYPE = {
    REAL_ESTATE: 'Real Estate',
    FUNDS: 'Funds',
    GOLD: 'Gold',
    WATCH: 'Watch',
    ART: 'Art',
    ASSET: 'Asset'
}

/**
 * Category types. value are matched mapped from api.
 * @readonly
 * @enum {number}
 */
export const CATEGORY_TYPE_ID = {
    REAL_ESTATE: 1,
    FUNDS: 9,
    GOLD: 7,
    WATCH: 8,
    ART: 2,
    ASSET: 47,
    TOKENIZED_ASSET: 61
}

/**
 * Sub Category types. value are matched mapped from api.
 * @readonly
 * @enum {number}
 */
export const SUB_CATEGORY_TYPE_ID = {
    OPEN_FUNDS: 54,
    CLOSE_FUNDS: 55
}

/**
 * The status of a documents signed.
 * @readonly
 * @enum {number}
 */
export const DOCUMENT_SIGN_STATUS = {
    PENDING: 1,
    SIGNED: 2,
    REJECTED: 3,
}

/**
 * Project Status for My project section Proposer
 * @readonly
 * @enum {number}
 */
export const PROJECT_STATUS = {
    DRAFT: 1,
    SUBMITTED: 2,
    REVIEWED: 3,
    PROJECT_LIVE: 4,
    REJECTED: 5,
    TOKENIZE_ASSET: 7
}

/**
 * Assigned  Project Status for My project section Proposer (DIC status)
 * @readonly
 * @enum {number}
 */
export const ASSIGNED_PROJECT_STATUS = {
    NOT_REVIEWED: 0,
    REVIEWED: 1,
    APPROVED: 2,
    REJECTED: 3,
    FUNDED: 4,
    INPROGRESS: 5,
    REJECTED_BY_PROPOSER: 6,
    INAUCTION: 8
}

/**
 * The status of a suggest review.
 * @readonly
 * @enum {number}
 */
export const SUGGEST_REVIEW = {
    PENDING: 1,
    ACCEPTED: 2,
    REJECTED: 3,
}

/**
 * Loan status.
 * @readonly
 * @enum {number}
 */
export const LOAN_STATUS = {
    ACTIVE: 1,
    CLOSED: 2,
    DEFAULTED: 3,
}

/**
 * Capital types.
 * @readonly
 * @enum {number}
 */
export const CAPITAL_TYPE_ID = {
    EQUITY: 1,
    SENIOR_DEBT: 4,
    JUNIOR_DEBT: 5,
}

/**
 * Human-readable display names for capital types.
 * @readonly
 * @enum {string}
 */
export const CAPITAL_TYPE_DISPLAY = {
    [CAPITAL_TYPE_ID.EQUITY]: 'Equity',
    [CAPITAL_TYPE_ID.SENIOR_DEBT]: 'Senior Debt',
    [CAPITAL_TYPE_ID.JUNIOR_DEBT]: 'Junior Debt',
};

/**
 * Single Capital Status.
 * @readonly
 * @enum {number}
 */
export const CAPITAL_TYPE_STATUS = {
    ACTIVE: 1,
    FUNDED: 2,
    CLOSED: 3,
}

/**
 * Transaction types for the application.
 * @readonly
 * @enum {string}
 */
export const TRANSACTION_TYPE = {
    SELL_ASSET: 'SELL_ASSET',
    LOAN_ALLOCATION: 'LOAN_ALLOCATION',
    LOAN_INSTALLMENT: 'LOAN_INSTALLMENT',
    MEMBERSHIP_STAKE: 'MEMBERSHIP_STAKE',
    SELL_PARCEL: 'SELL_PARCEL',
    SELL_EQUITY: 'SELL_EQUITY',
    DEPOSIT: 'DEPOSIT',
    FULL_PAYMENT: 'FULL_PAYMENT',
    DIVIDEND_RECEIVED: 'DIVIDEND_RECEIVED',
    FUND_DISTRIBUTED: 'FUND_DISTRIBUTED',
    EXIT_AMOUNT: "EXIT_AMOUNT"
};

/**
 * Human-readable display names for transaction types.
 * @readonly
 * @enum {string}
 */
export const TRANSACTION_TYPE_DISPLAY = {
    SELL_ASSET: 'Sell Asset',
    LOAN_ALLOCATION: 'Loan Allocation',
    LOAN_INSTALLMENT: 'Loan Installment',
    MEMBERSHIP_STAKE: 'Membership Stake',
    SELL_PARCEL: 'Sell Parcel',
    SELL_EQUITY: 'Sell Equity',
    DEPOSIT: 'Deposit',
    FULL_PAYMENT: 'Full Payment',
    FUND_ALLOCATION: 'Fund Allocation',
    LOAN_PREPAYMENT: 'Loan Prepayment',
    LOAN_MIN_PREPAYMENT: 'Loan Minimum Prepayment',
    DIVIDEND_RECEIVED: 'Dividend Received',
    FUND_DISTRIBUTED: 'Fund Distribution',
    AMOUNT_RETURNED: 'Amount Returned',
    PLATFORM_FEES: 'Platform Fees',
    DIC_COMMISSION: 'DIC Commission Fees',
    STAKE_AMOUNT: 'Stake Amount',
    DEBT_REPAYMENT: 'Debt Repayment',
    EXIT_AMOUNT: 'Exit Amount'
};

/**
 * Status of a Loan Installment.
 * @readonly
 * @enum {number}
 */
export const LOAN_INSTALLMENT_STATUS = {
    PENDING: 1,
    PAID: 2,
    DEFAULTED: 3,
    PARTIALLY_DEFAULTED: 4,
    FORECLOSED: 5,
    PARTIALLY_PAID: 6,
}

/**
 * Human-readable display names for loan installment statuses.
 * @readonly
 * @enum {string}
 */
export const LOAN_INSTALLMENT_STATUS_DISPLAY = {
    PENDING: 'Due',
    PAID: 'Paid',
    DEFAULTED: 'Defaulted',
    PARTIALLY_DEFAULTED: 'Partially Defaulted'
};

/**
 *  SHUFTI PRO Kyc events , also used to know status of a kyc request.
 * @readonly 
 * @enum {string}
 */
export const SHUFTI_KYC_EVENTS = {
    REQUEST_PENDING: "request.pending",
    REQUEST_INVALID: "request.invalid",
    REQUEST_TIMEOUT: "request.timeout",
    REQUEST_UNAUTHORIZED: "request.unauthorized",
    REQUEST_DELETED: "request.deleted",
    REQUEST_RECEIVED: "request.received",
    VERIFICATION_CANCELLED: "verification.cancelled",
    VERIFICATION_ACCEPTED: "verification.accepted",
    VERIFICATION_DECLINED: "verification.declined",
    VERIFICATION_STATUS_CHANGED: "verification.status.changed",
    REVIEW_PENDING: "review.pending",
    NOT_INITIATED: "not.initiated", //custom event for not initiated
};

/**
 * Human-readable display names for SHUFTI_KYC_EVENTS.
 * @readonly
 * @enum {string}
 */
export const SHUFTI_KYC_EVENTS_DISPLAY = {
    [SHUFTI_KYC_EVENTS.REQUEST_PENDING]: "Request Pending",
    [SHUFTI_KYC_EVENTS.REQUEST_INVALID]: "Request Invalid",
    [SHUFTI_KYC_EVENTS.REQUEST_TIMEOUT]: "Request Timeout",
    [SHUFTI_KYC_EVENTS.REQUEST_UNAUTHORIZED]: "Request Unauthorized",
    [SHUFTI_KYC_EVENTS.REQUEST_DELETED]: "Deleted",
    [SHUFTI_KYC_EVENTS.REQUEST_RECEIVED]: "Request Received",
    [SHUFTI_KYC_EVENTS.VERIFICATION_CANCELLED]: "Verification Cancelled",
    [SHUFTI_KYC_EVENTS.VERIFICATION_ACCEPTED]: "Verification Accepted",
    [SHUFTI_KYC_EVENTS.VERIFICATION_DECLINED]: "Verification Declined",
    [SHUFTI_KYC_EVENTS.VERIFICATION_STATUS_CHANGED]: "Verification Status Changed",
    [SHUFTI_KYC_EVENTS.REVIEW_PENDING]: "Pending Review",
    [SHUFTI_KYC_EVENTS.NOT_INITIATED]: "Not Initiated",
};

/**
 * Colors for respective SHUFTI_KYC_EVENTS.
 * @readonly
 * @enum {string}
 */
export const SHUFTI_KYC_EVENTS_COLORS = {
    [SHUFTI_KYC_EVENTS.REQUEST_PENDING]: "blue",
    [SHUFTI_KYC_EVENTS.REQUEST_INVALID]: "red",
    [SHUFTI_KYC_EVENTS.REQUEST_TIMEOUT]: "orange",
    [SHUFTI_KYC_EVENTS.REQUEST_UNAUTHORIZED]: "yellow",
    [SHUFTI_KYC_EVENTS.REQUEST_DELETED]: "gray",
    [SHUFTI_KYC_EVENTS.REQUEST_RECEIVED]: "green",
    [SHUFTI_KYC_EVENTS.VERIFICATION_CANCELLED]: "purple",
    [SHUFTI_KYC_EVENTS.VERIFICATION_ACCEPTED]: "green",
    [SHUFTI_KYC_EVENTS.VERIFICATION_DECLINED]: "red",
    [SHUFTI_KYC_EVENTS.VERIFICATION_STATUS_CHANGED]: "blue",
    [SHUFTI_KYC_EVENTS.REVIEW_PENDING]: "yellow",
    [SHUFTI_KYC_EVENTS.NOT_INITIATED]: "gray",
};


export const STATUS_OF_PROJECT = {
    DRAFT: 1,
    SUBMITTED: 2,
    INREVIEW: 3,
    REVIEWED: 4,
    PROJECT_LIVE: 5,
    REJECTED: 6,
    INAUCTION: 8
}

export const SET_OF_RULES = {
    batchAllowCountry: "0x84778B0EDD1346e3cFf76B40AFAfA7d35a191875",
    batchDisAllowCountry: "0x84778B0EDD1346e3cFf76B40AFAfA7d35a191875",
    batchRestricCountry: "0x5Af3271161D95014458CaEaf044442B713095ff0",
    batchUnRestricCountry: "0x5Af3271161D95014458CaEaf044442B713095ff0",
    setMaxBalance: "0xd677fF6fc911962d780F6301010Bbe86646BE24e",
    batchAllowUser: "0x4843F67e79352b00FF9aFeBF1465fdD5c3166f2F",
    batchDisallowUsers: "0x4843F67e79352b00FF9aFeBF1465fdD5c3166f2F",
    setTransferFees: "0xfCb9b25F2054B359645e505D6694287D7B71282f", // *
    setSupplyLimit: "0x0E7899d50C3acf08212bbd184D1308a62F657063",
    setTimeTransfer: "0x2a8D26084C334504d172882870DDBd6d15c0FB81",
    setExchangeLimit: "0x8f14185860eD58fF25e41Be8F3F78a3382F646AD"
}

export const CONTRACT_NAME = {
    NFT: 'NFT',
    TOKENIZE_NFT: 'TOKENIZE_NFT',
    BLUPRINT: 'BLUPRINT',
    HELPER_MODULE: 'HELPER_MODULE',
    CLOSE_FUND_TOKEN: 'CLOSE_FUND_TOKEN',
    OPEN_FUND_TOKEN: 'OPEN_FUND_TOKEN',
    SPV_FUND_TOKEN: 'SPV_FUND_TOKEN',
    CALCULATE_EMI: 'CalculateEMI',
    CALCULATE_BALLON_EMI: 'CalculateBallonPayment',
    RESTRICT_COUNTRY: 'batchRestricCountry',
    SET_TRANSFER_FEES: 'setTransferFees',

    CREATE_PROPOSAL_SALE: 'CreateSaleProposal',
    STAKE_CONTRACT_SALE: 'StakingSalePool',
    DIC_PROPOSAL_SALE: 'DICSaleProposal',
    ART_SALE_MARKETPLACE: 'ArtSaleMarketplace',

    CREATE_PROPOSAL_PARCEL: 'CreateParcelProposal',
    STAKE_CONTRACT_PARCEL: 'StakingParcelPool',
    DIC_PROPOSAL_PARCEL: 'DICParcelProposal',
    ART_PARCEL_MARKETPLACE: 'ArtParcelMarketplace',

    CREATE_PROPOSAL_LOAN: 'CreateLoanProposal',
    STAKE_CONTRACT_LOAN: 'StakingLoanPool',
    DIC_PROPOSAL_LOAN: 'DICLoanProposal',
    WATCH_MARKETPLACE: 'LoanMarketplace',
    WATCH_DEBT_OFFER_ADDRESS: 'LoanDebtOffers',
    WATCH_REPAYMENT_ADDRESS: 'LoanRepayment',

    CREATE_PROPOSAL_CLOSE_FUND: 'CreateCloseFundProposal',
    STAKE_CONTRACT_CLOSE_FUND: 'StakingCloseFundPool',
    DIC_PROPOSAL_CLOSE_FUND: 'DICCloseFundProposal',
    CLOSE_FUNDS_MARKETPLACE: 'CloseFundMarketplace',
    CLOSE_FUNDS_INVESTMENT: 'CloseFundInvestment',
    CLOSE_FUNDS_DISTRIBUTION: 'CloseFundDistribution',

    CREATE_PROPOSAL_OPEN_FUND: 'CreateFundProposal',
    STAKE_CONTRACT_OPEN_FUND: 'StakingFundPool',
    DIC_PROPOSAL_OPEN_FUND: 'DICFundProposal',
    FUNDS_MARKETPLACE: 'FundMarketplace',
    FUNDS_INVESTMENT: 'FundInvestment',
    FUNDS_DISTRIBUTION: 'FundDistribution',

    CREATE_PROPOSAL_SPV: 'CreateSPVProposal',
    DIC_PROPOSAL_SPV: 'DICSPVProposal',
    STAKE_CONTRACT_SPV: 'StakingSPVPool',
    SPV_MARKETPLACE: 'SPVMarketplace',
    SPV_DEBT_INVESTMENT: 'SPVDebtInvestments',
    SPV_EQUITY_INVESTMENT: 'SPVEquityInvestments',
    SPV_DEBT_REPAYMENT: 'SPVDebtRepayment',
    SPV_EQUITY_DISTRIBUTION: 'SPVEquityDistribution'
}