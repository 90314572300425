import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Box, Button, IconButton, InputAdornment, Paper, Stack, Tab, Tabs, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import styled from '@emotion/styled';
import { CAPITAL_TYPE_ID, CAPITAL_TYPE_STATUS, CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID } from '../../../constants';
import { GetApiParam } from '../../../Api/Api';
import { Info, ArrowBack, AttachMoney, Percent } from '@mui/icons-material';
import NumericFormatCustom from '../../../Components/Common/NumericFormatCustom';
import "./Fractionalize.css"
import { formatNumber } from '../../../Components/Common/USFormat';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: '#05131B',
    borderRadius: '8px',
  },
  '& .MuiFilledInput-root:hover': {
    backgroundColor: '#05131B',
  },

  '& .MuiFilledInput-root.Mui-focused': {
    backgroundColor: '#05131B',
  },
  '& .MuiInputBase-input.MuiFilledInput-input': {
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16px',
  },
  '& .MuiInputBase-input.Mui-focused': {
    backgroundColor: '#05131B'
  },
  '& .MuiFilledInput-underline:before': {
    display: 'none',
  },
  '& .MuiFilledInput-underline:after': {
    display: 'none',
  },
  '& .MuiFilledInput-underline:hover:before': {
    display: 'none',
  },
  '& .MuiFilledInput-underline:hover:after': {
    display: 'none',
  },
}));

function formatDecimalNumber(number) {
  if (Number.isInteger(number)) {
    return number.toString(); // Return the number without decimals
  } else {
    const parts = number.toString().split('.');
    if (parts[1] && parts[1].length > 2) {
      return number.toFixed(2); // Fix to 2 decimal places if more than 2 decimals
    }
    return number.toString(); // Keep the number as-is if decimals are already <= 2
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Box
      key={props.keyvalue}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </Box >
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  }
}

const Fractionalize = forwardRef(({ assetId, assetData, assetTitle, changeAmount, capitalData, setSelectedCapitalId, selectedCapitalId, state, disableCondition, paymentMethod, changeClassType, changeSpvType// state var: to get the selected capital id exchanging with parent and also using here
}, ref) => {

  const theme = useTheme()
  const [value, setValue] = useState(0); //To change Tabs and SwipeableViews value and index
  const navigate = useNavigate();
  const equityRef = useRef(null); //equity ref
  const jDRef = useRef(null); //junior debt ref
  const sDRef = useRef(null); //senior debt ref
  const fundsClassRef = useRef(null); //fund class ref
  const fundsSpaceXRef = useRef(null); //fund close ref

  const [fundedAmount, setFundedAmount] = useState(0);
  const [totalFundedAmount, setTotalFundedAmount] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let cId = event.target.name.split(':')[1] ?? 0;
    setSelectedCapitalId(cId)
  };


  const handleChangeIndex = index => {
    setValue(index)
  }

  useEffect(() => {
    if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
      let selectedCapitalId = 1;
      if (capitalData?.find((item) => item.capital_type?.id === CAPITAL_TYPE_ID.EQUITY)) {
        selectedCapitalId = CAPITAL_TYPE_ID.EQUITY;
      } else if (capitalData?.find((item) => item.capital_type?.id === CAPITAL_TYPE_ID.JUNIOR_DEBT)) {
        selectedCapitalId = CAPITAL_TYPE_ID.JUNIOR_DEBT;
      } else if (capitalData?.find((item) => item.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT)) {
        selectedCapitalId = CAPITAL_TYPE_ID.SENIOR_DEBT;
      }
      setSelectedCapitalId(selectedCapitalId)
    }
  }, [])


  const handleBackBtn = () => {
    navigate(`/project-details/${state?.assetId}`, { replace: true, state: { from: 'payment-page' } })
  }

  const changeLocalAmount = (amount) => {
    changeAmount(amount)
  }

  useImperativeHandle(ref, () => ({
    getData
  }));

  const getData = () => {
    //value is checked to get the current tab value which is selected and refers to the index of the array map
    if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
      if (fundsSpaceXRef.current.validationCheck()) {
        return fundsSpaceXRef.current.getCapitalData()
      }
    }
    else if ((parseInt(selectedCapitalId) == 1 || parseInt(selectedCapitalId) == 2) && assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
      if (fundsClassRef.current.validationCheck()) {
        return fundsClassRef.current.getCapitalData()
      }
    } else if (parseInt(selectedCapitalId) === CAPITAL_TYPE_ID.EQUITY) { //is Equity
      if (equityRef.current.validationCheck()) {
        return equityRef.current.getCapitalData()
      }
    }
    if (parseInt(selectedCapitalId) === CAPITAL_TYPE_ID.JUNIOR_DEBT) { //is Junior Debt
      if (jDRef.current.validationCheck()) {
        return jDRef.current.getCapitalData()
      }
    }
    if (parseInt(selectedCapitalId) === CAPITAL_TYPE_ID.SENIOR_DEBT) { //is Senior Debt
      if (sDRef.current.validationCheck()) {
        return sDRef.current.getCapitalData()
      }
    }
    return false
  }

  useEffect(() => {
    const getFundedAmount = async () => {
      const resp = await GetApiParam('/invest/getAssetFundedAmount', { "asset_id": assetId })
      if (resp?.data?.code === 200) {
        let fundedData = resp?.data?.data;
        let amount = fundedData[`capital_${selectedCapitalId}_totalAmount`] ? fundedData[`capital_${selectedCapitalId}_totalAmount`] : 0;
        if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
          // if (Object.keys(fundedData).length > 1) {
          //   let amount1 = fundedData[`capital_${1}_totalAmount`] ? fundedData[`capital_${1}_totalAmount`] : 0;
          //   let amount2 = fundedData[`capital_${2}_totalAmount`] ? fundedData[`capital_${2}_totalAmount`] : 0;
          //   setTotalFundedAmount(parseFloat(amount1) + parseFloat(amount2))
          // } else {
          //   let amount = fundedData[`capital_${selectedCapitalId}_totalAmount`] ? fundedData[`capital_${selectedCapitalId}_totalAmount`] : 0;
          //   setTotalFundedAmount(parseFloat(amount))
          // }
          setTotalFundedAmount(resp?.data?.totalAmount)
        } else {
          setTotalFundedAmount(resp?.data?.totalAmount)
        }
        setFundedAmount(parseFloat(amount))
      }
    }
    if (assetId) {
      const clear = setTimeout(() => {
        getFundedAmount()
      }, 100)
      return () => clearTimeout(clear)
    }
  }, [value])

  return (
    <>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }} className='rc-basic-container'>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
          <IconButton onClick={handleBackBtn} sx={{ color: '#ffffff' }}>
            <ArrowBack />
          </IconButton>
          <Typography variant='h6' sx={{ flexGrow: 1 }}>Invest in {assetTitle}</Typography>
        </Stack>
        <Box component={'hr'} className='rc-hr' />
        {assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ?
          <>
            <FundsSpaceX ref={fundsSpaceXRef} assetData={assetData} totalFundedAmount={totalFundedAmount} fundedAmount={fundedAmount} localChangeAmount={changeLocalAmount} disableCondition={disableCondition} paymentMethod={paymentMethod} />
          </>
          :
          <>
            <Box className="fractional-tabs">
              <Tabs
                onChange={handleChange}
                value={value}
                aria-label="Tabs where each tab needs to be selected manually"
              >
                {
                  capitalData?.map((capital, index) => {
                    return capital?.class ? <Tab name={`${capital?.class}:${capital?.class}`} key={index} label={capital?.class == 1 ? "Class A" : "Class B"} className={value === index ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(capital?.class)} /> :
                      <Tab name={`${capital.capital_type.capital_name}:${capital?.capital_type?.id}`} key={index} label={capital.capital_type.capital_name} className={value === index ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(capital?.capital_type?.id)} />
                  })
                }
              </Tabs>
            </Box>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              {
                capitalData?.map((capital, index) => {
                  return capital?.class ?
                    <TabPanel keyvalue={`Fractionalize-panel-${index}`} value={value} index={index} >
                      {
                        (capital?.class == 1 || capital?.class == 2) &&
                        <FundsClass ref={fundsClassRef} assetData={assetData} totalFundedAmount={totalFundedAmount} fundedAmount={fundedAmount} equityData={capital} localChangeAmount={changeLocalAmount} classType={capital?.class} changeClassType={changeClassType} />
                      }
                    </TabPanel>
                    :
                    <TabPanel keyvalue={`Fractionalize-panel-${index}`} value={value} index={index} >
                      {
                        capital.capital_type.id === CAPITAL_TYPE_ID.EQUITY &&
                        <Equity ref={equityRef} fundedAmount={fundedAmount} equityData={capital} localChangeAmount={changeLocalAmount} changeSpvType={changeSpvType} />
                      }
                      {
                        capital.capital_type.id === CAPITAL_TYPE_ID.JUNIOR_DEBT &&
                        <JuniorDebt ref={jDRef} fundedAmount={fundedAmount} juniorDebtData={capital} localChangeAmount={changeLocalAmount} changeSpvType={changeSpvType} />
                      }
                      {
                        capital.capital_type.id === CAPITAL_TYPE_ID.SENIOR_DEBT &&
                        <SeniorDebt ref={sDRef} fundedAmount={fundedAmount} seniorDebtData={capital} localChangeAmount={changeLocalAmount} changeSpvType={changeSpvType} />
                      }
                    </TabPanel>
                })
              }
            </SwipeableViews>
          </>
        }
      </Paper>
    </>
  )
});

export default Fractionalize

/**
 * class A and class B type Panel
 */
const FundsClass = forwardRef(({ fundedAmount, totalFundedAmount, assetData, equityData, localChangeAmount, classType, changeClassType }, ref) => {

  //for now doing only equity type
  const [investAmount, setInvestAmount] = useState(0)
  const [investAmountError, setInvestAmountError] = useState({ error: false, message: '' })
  const projectAmount = assetData?.fractionalize_total_price
  const [tooltipOpen, setTooltipOpen] = useState({ 1: false });
  const isMobileOrTab = useMediaQuery('(max-width: 1024px)');

  const handleChangeInvestAmount = (event) => {
    let value = event.target.value
    setInvestAmount(value);
    amountValidation(value)
  };

  // Expose the child function through the ref
  useImperativeHandle(ref, () => ({
    validationCheck, getCapitalData
  }));

  useEffect(() => {
    localChangeAmount(investAmount)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investAmount])

  const validationCheck = () => {
    let validatedAmount = amountValidation(investAmount); // separated because need to run both
    return validatedAmount
  }

  const amountValidation = (amount = investAmount) => {
    let value = parseFloat(amount)
    let remainingAmount = parseFloat(projectAmount) - parseFloat(totalFundedAmount)
    if (equityData?.status === CAPITAL_TYPE_STATUS.FUNDED) {
      setInvestAmountError({ error: true, message: 'Capital is already funded' })
      return false
    }
    else if (isNaN(value)) {
      setInvestAmountError({ error: true, message: 'Amount is required' })
      return false
    } else if (value === 0) {
      setInvestAmountError({ error: true, message: 'Amount Should be greater than zero' })
      return false
    } else if (value > remainingAmount) {
      setInvestAmountError({ error: true, message: `Amount should be less than or equal to $${remainingAmount}` })
      return false
    } else if (value < equityData?.minimum_investment) {
      setInvestAmountError({ error: true, message: `Minimum investment amount is $${equityData?.minimum_investment}` })
      return false
    } else { //no error
      setInvestAmountError({ error: false, message: '' })
      return true
    }
  }

  const getCapitalData = () => {
    return { offer_amount: parseFloat(investAmount), class: classType }
  }
  useEffect(() => {
    if (changeClassType) {
      changeClassType(classType)
    }
  }, [classType])


  /**
   * Tooltip Close function
   * @param {*} tooltipId 
   */
  const handleTooltipClose = (tooltipId) => {
    if (isMobileOrTab) {
      setTooltipOpen(prevState => ({
        ...prevState,
        [tooltipId]: false
      }));
    }
  };

  /**
  * Tooltip Open Function
  * @param {*} tooltipId 
  */
  const handleTooltipOpen = (tooltipId) => {
    if (isMobileOrTab) {
      setTooltipOpen(prevState => ({
        ...prevState,
        [tooltipId]: true
      }));
    }
  };

  return (
    <>
      <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>You Pay</Typography>
      <Box className="payment-enter-investor">
        <CustomTextField onChange={handleChangeInvestAmount}
          value={investAmount}
          hiddenLabel
          placeholder="Amount"
          disabled={equityData?.status === CAPITAL_TYPE_STATUS.FUNDED}
          variant='filled'
          error={investAmountError.error}
          helperText={investAmountError.message}
          FormHelperTextProps={{ sx: { color: 'white' } }}
          fullWidth InputProps={{
            inputComponent: NumericFormatCustom,
            startAdornment: <InputAdornment position="start"><AttachMoney sx={{ color: '#EDE4C5' }} /></InputAdornment>,
            endAdornment: <InputAdornment position="end"><Button
              disabled={equityData?.status === CAPITAL_TYPE_STATUS.FUNDED}
              onClick={() => { setInvestAmount(parseFloat(projectAmount) - parseFloat(totalFundedAmount)); amountValidation(parseFloat(projectAmount) - parseFloat(totalFundedAmount)) }} className="rc-tertiary-btn btn-golden" style={{
                padding: '0px', height: '27px', marginRight: '11px'
              }} variant="filled"> max</Button>
              <Tooltip classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow', }} PopperProps={{ style: { maxWidth: '200px', maxHeight: '130px' } }}
                title="" placement='bottom' arrow open={isMobileOrTab ? tooltipOpen[1] : undefined}
                onClose={() => handleTooltipClose(1)}><Info style={{ color: 'white' }}
                  onClick={() => handleTooltipOpen(1)}
                  onMouseOver={isMobileOrTab ? undefined : () => handleTooltipOpen(1)}
                  onMouseOut={isMobileOrTab ? undefined : () => handleTooltipClose(1)} /></Tooltip>
            </InputAdornment>,
          }} sx={{ input: { color: '#94A3B8 !important' } }}
        // onInput={(event) => {
        //   event.target.value = event.target.value
        //     .replace(/[^\d.]/g, "")
        // }}

        />
      </Box>
    </>

  )
});

/**
 * Equity Panel
 */
const Equity = forwardRef(({ fundedAmount, equityData, localChangeAmount, changeSpvType }, ref) => {

  //for now doing only equity type
  const [investAmount, setInvestAmount] = useState(0)
  const [equityPercentage, setEquityPercentage] = useState(0)
  const [investAmountError, setInvestAmountError] = useState({ error: false, message: '' })
  const [equityPercentageError, setEquityPercentageError] = useState({ error: false, message: '' })
  // const [tooltipOpen, setTooltipOpen] = useState({ 2: false });
  // const isMobileOrTab = useMediaQuery('(max-width: 1024px)');

  // const handleChangeEquity = (event) => {
  //   let value = event.target.value
  //   setEquityPercentage(value);
  //   equityValidation(value)
  // };

  const handleChangeInvestAmount = (event) => {
    let value = event.target.value
    setInvestAmount(value);
    amountValidation(value)
  };

  useEffect(() => {
    localChangeAmount(investAmount);
    let equityPer = parseFloat((investAmount / equityData?.total_raise) * equityData?.equity).toFixed(2)
    setEquityPercentage(equityPer)
  }, [investAmount])

  // Expose the child function through the ref
  useImperativeHandle(ref, () => ({
    validationCheck, getCapitalData
  }));


  const validationCheck = () => {
    let validatedAmount = amountValidation(investAmount); // seperated because need to run both
    // let validatedEquity = equityValidation(equityPercentage);
    return validatedAmount
  }

  const amountValidation = (amount = investAmount) => {

    let value = parseFloat(amount)
    let remainingAmount = parseFloat(equityData?.total_raise) - parseFloat(fundedAmount)
    if (equityData?.status === CAPITAL_TYPE_STATUS.FUNDED) {
      setInvestAmountError({ error: true, message: 'Capital is already funded' })
      return false
    }
    else if (isNaN(value)) {
      setInvestAmountError({ error: true, message: 'Amount is required' })
      return false
    } else if (value === 0) {
      setInvestAmountError({ error: true, message: 'Amount Should be greater than zero' })
      return false
    } else if (value > remainingAmount) {
      setInvestAmountError({ error: true, message: `Amount should be less than or equal to $${formatNumber(remainingAmount)}` })
      return false
    } else if (value < equityData?.minimum_investment) {
      setInvestAmountError({ error: true, message: `Minimum investment amount is $${formatNumber(equityData?.minimum_investment)}` })
      return false
    } else { //no error
      setInvestAmountError({ error: false, message: '' })
      return true
    }
  }

  const equityValidation = (equity = equityPercentage) => {
    let value = parseFloat(equity)
    if (equityData?.status === CAPITAL_TYPE_STATUS.FUNDED) {
      setInvestAmountError({ error: true, message: 'Capital is already funded' })
      return false
    }
    else if (isNaN(value)) {
      setEquityPercentageError({ error: true, message: 'Equity is required' })
      return false
    } else if (value === 0) {
      setEquityPercentageError({ error: true, message: 'Equity Should be greater than zero' })
      return false
    } else if (value > parseFloat(equityData?.equity)) {
      setEquityPercentageError({ error: true, message: `Equity should be less than or equal to ${equityData?.equity}` })
      return false
    } else { //no error
      setEquityPercentageError({ error: false, message: '' })
      return true
    }
  }

  const getCapitalData = () => {
    return { offer_amount: parseFloat(investAmount), fractional_ror: parseFloat(equityData?.rate_of_return ? equityData?.rate_of_return : 0), equity_percent: parseFloat(equityPercentage), capital_type_id: equityData.capital_type.id }
  }

  useEffect(() => {
    if (changeSpvType) {
      changeSpvType(equityData.capital_type.id)
    }
  }, [])

  /**
   * Tooltip Close function
   * @param {*} tooltipId 
   */
  // const handleTooltipClose = (tooltipId) => {
  //   if (isMobileOrTab) {
  //     setTooltipOpen(prevState => ({
  //       ...prevState,
  //       [tooltipId]: false
  //     }));
  //   }
  // };

  /**
  * Tooltip Open Function
  * @param {*} tooltipId 
  */
  // const handleTooltipOpen = (tooltipId) => {
  //   if (isMobileOrTab) {
  //     setTooltipOpen(prevState => ({
  //       ...prevState,
  //       [tooltipId]: true
  //     }));
  //   }
  // };

  return (
    <>

      <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>You Pay</Typography>
      <Box className="payment-enter-investor">
        <CustomTextField onChange={handleChangeInvestAmount}
          value={investAmount}
          hiddenLabel
          placeholder="Amount"
          disabled={equityData?.status === CAPITAL_TYPE_STATUS.FUNDED}
          variant='filled'
          error={investAmountError.error}
          helperText={investAmountError.message}
          FormHelperTextProps={{ sx: { color: 'white' } }}
          fullWidth InputProps={{
            inputComponent: NumericFormatCustom,
            startAdornment: <InputAdornment position="start"><AttachMoney sx={{ color: '#EDE4C5' }} /></InputAdornment>,
            endAdornment: <InputAdornment position="end"><Button
              disabled={equityData?.status === CAPITAL_TYPE_STATUS.FUNDED}
              onClick={() => { setInvestAmount(formatDecimalNumber(parseFloat(equityData?.total_raise) - parseFloat(fundedAmount))); amountValidation(formatDecimalNumber(parseFloat(equityData?.total_raise) - parseFloat(fundedAmount))) }} className="rc-tertiary-btn btn-golden" style={{
                padding: '0px', height: '27px', marginRight: '11px'
              }} variant="filled"> max</Button>
              {/* <Tooltip classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow', }} PopperProps={{ style: { maxWidth: '200px', maxHeight: '130px' } }} title= ""  placement='bottom' arrow open={isMobileOrTab ? tooltipOpen[2] : undefined}
              ><Info style={{ color: 'white' }} onClick={() => handleTooltipOpen(2)}
                onMouseOver={isMobileOrTab ? undefined : () => handleTooltipOpen(2)}
                onMouseOut={isMobileOrTab ? undefined : () => handleTooltipClose(2)} /></Tooltip> */}
            </InputAdornment>,
          }} sx={{ input: { color: '#94A3B8 !important' } }}
        // onInput={(event) => {
        //   event.target.value = event.target.value
        //     .replace(/[^\d.]/g, "")
        // }}
        />
      </Box>
      <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>You Receive </Typography>
      <Box className="payment-enter-investor">
        <CustomTextField value={equityPercentage}
          hiddenLabel
          placeholder="Equity"
          variant='filled'
          // disabled={equityData?.status === CAPITAL_TYPE_STATUS.FUNDED}
          disabled={true}
          fullWidth
          error={equityPercentageError.error}
          helperText={equityPercentageError.message}
          InputProps={{
            startAdornment: <InputAdornment position="start"><Percent sx={{ color: '#EDE4C5' }} /></InputAdornment>
          }} sx={{ input: { color: '#94A3B8 !important' } }}
        />
      </Box>
    </>
  )
});

/**
 * Junior Debt Panel  
 */
const JuniorDebt = forwardRef(({ fundedAmount, juniorDebtData, localChangeAmount, changeSpvType }, ref) => {

  const [investAmount, setInvestAmount] = useState(0)
  const [investAmountError, setInvestAmountError] = useState({ error: false, message: '' })
  const [tooltipOpen, setTooltipOpen] = useState({ 3: false });
  const isMobileOrTab = useMediaQuery('(max-width: 1024px)');

  const handleChangeInvestAmount = (event) => {
    let value = event.target.value
    setInvestAmount(value);
    amountValidation(value)
  };


  useEffect(() => {
    localChangeAmount(investAmount)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investAmount])

  // Expose the child function through the ref
  useImperativeHandle(ref, () => ({
    validationCheck, getCapitalData
  }));

  const validationCheck = () => {
    let validatedAmount = amountValidation(investAmount); // seperated because need to run both
    return validatedAmount
  }

  const amountValidation = (amount = investAmount) => {
    let value = parseFloat(amount)
    let remainingAmount = parseFloat(juniorDebtData?.total_raise) - parseFloat(fundedAmount)
    if (juniorDebtData?.status === CAPITAL_TYPE_STATUS.FUNDED) {
      setInvestAmountError({ error: true, message: 'Capital is already funded' })
      return false
    }
    else if (isNaN(value)) {
      setInvestAmountError({ error: true, message: 'Amount is required' })
      return false
    } else if (value === 0) {
      setInvestAmountError({ error: true, message: 'Amount Should be greater than zero' })
      return false
    } else if (value > remainingAmount) {
      setInvestAmountError({ error: true, message: `Amount should be less than or equal to $${remainingAmount}` })
      return false
    } else if (value < juniorDebtData?.minimum_investment) {
      setInvestAmountError({ error: true, message: `Minimum investment amount is $${juniorDebtData?.minimum_investment}` })
      return false
    }
    else { //no error
      setInvestAmountError({ error: false, message: '' })
      return true
    }
  }

  const getCapitalData = () => {
    return { offer_amount: parseFloat(investAmount), capital_type_id: juniorDebtData.capital_type.id }
  }

  useEffect(() => {
    if (changeSpvType) {
      changeSpvType(juniorDebtData.capital_type.id)
    }
  }, [])
  /**
   * Tooltip Close function
   * @param {*} tooltipId 
   */
  const handleTooltipClose = (tooltipId) => {
    if (isMobileOrTab) {
      setTooltipOpen(prevState => ({
        ...prevState,
        [tooltipId]: false
      }));
    }
  };

  /**
  * Tooltip Open Function
  * @param {*} tooltipId 
  */
  const handleTooltipOpen = (tooltipId) => {
    if (isMobileOrTab) {
      setTooltipOpen(prevState => ({
        ...prevState,
        [tooltipId]: true
      }));
    }
  };

  return (
    <>
      <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>Debt Amount</Typography>
      <Box className="payment-enter-investor">
        <CustomTextField
          onChange={handleChangeInvestAmount}
          value={investAmount}
          hiddenLabel
          placeholder="Amount"
          disabled={juniorDebtData?.status === CAPITAL_TYPE_STATUS.FUNDED}
          variant='filled'
          error={investAmountError.error}
          helperText={investAmountError.message}
          FormHelperTextProps={{ sx: { color: 'white' } }}
          fullWidth InputProps={{
            inputComponent: NumericFormatCustom,
            startAdornment: <InputAdornment position="start"><AttachMoney sx={{ color: '#EDE4C5' }} /></InputAdornment>,
            endAdornment: <InputAdornment position="end"><Button
              disabled={juniorDebtData?.status === CAPITAL_TYPE_STATUS.FUNDED}
              onClick={() => { setInvestAmount(formatDecimalNumber(parseFloat(juniorDebtData?.total_raise) - parseFloat(fundedAmount))); amountValidation(formatDecimalNumber(parseFloat(juniorDebtData?.total_raise) - parseFloat(fundedAmount))) }} className="rc-tertiary-btn btn-golden" style={{
                padding: '0px', height: '27px', marginRight: '11px'
              }} variant="filled"> max</Button> <Tooltip classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow', }} PopperProps={{ style: { maxWidth: '200px', maxHeight: '130px' } }} title={<span className='font-14'>You can submit dept amount between the minimum investment to the max loan amount requested</span>} placement='bottom' arrow open={isMobileOrTab ? tooltipOpen[3] : undefined}><Info style={{ color: 'white' }} onClick={() => handleTooltipOpen(3)}
                onMouseOver={isMobileOrTab ? undefined : () => handleTooltipOpen(3)}
                onMouseOut={isMobileOrTab ? undefined : () => handleTooltipClose(3)} /></Tooltip></InputAdornment>,
          }} sx={{ input: { color: '#94A3B8 !important' } }}
        // onInput={(event) => {
        //   event.target.value = event.target.value
        //     .replace(/[^\d.]/g, "")
        // }}
        />
      </Box>
    </>

  )
});

/**
 * Senior Debt Panel
 */
const SeniorDebt = forwardRef(({ fundedAmount, seniorDebtData, localChangeAmount, changeSpvType }, ref) => {

  const [investAmount, setInvestAmount] = useState(0)
  const [investAmountError, setInvestAmountError] = useState({ error: false, message: '' })
  const [tooltipOpen, setTooltipOpen] = useState({ 4: false });
  const isMobileOrTab = useMediaQuery('(max-width: 1024px)');

  const handleChangeInvestAmount = (event) => {
    let value = event.target.value
    setInvestAmount(value);
    amountValidation(value)
  };

  useEffect(() => {
    localChangeAmount(investAmount)//eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investAmount])

  // Expose the child function through the ref
  useImperativeHandle(ref, () => ({
    validationCheck, getCapitalData
  }));

  const validationCheck = () => {
    let validatedAmount = amountValidation(investAmount); // separated because need to run both
    return validatedAmount
  }

  const amountValidation = (amount = investAmount) => {
    let value = parseFloat(amount)
    let remainingAmount = parseFloat(parseFloat(seniorDebtData?.total_raise) - parseFloat(fundedAmount))
    if (seniorDebtData?.status === CAPITAL_TYPE_STATUS.FUNDED) {
      setInvestAmountError({ error: true, message: 'Capital is already funded' })
      return false
    }
    else if (isNaN(value)) {
      setInvestAmountError({ error: true, message: 'Amount is required' })
      return false
    } else if (value === 0) {
      setInvestAmountError({ error: true, message: 'Amount Should be greater than zero' })
      return false
    } else if (value > remainingAmount) {
      setInvestAmountError({ error: true, message: `Amount should be less than or equal to $${remainingAmount}` })
      return false
    } else if (value < seniorDebtData?.minimum_investment) {
      setInvestAmountError({ error: true, message: `Minimum investment amount is $${seniorDebtData?.minimum_investment}` })
      return false
    }
    else { //no error
      setInvestAmountError({ error: false, message: '' })
      return true
    }
  }

  const getCapitalData = () => {
    return { offer_amount: investAmount, capital_type_id: seniorDebtData.capital_type.id }
  }

  useEffect(() => {
    if (changeSpvType) {
      changeSpvType(seniorDebtData.capital_type.id)
    }
  }, [])

  /**
   * Tooltip Close function
   * @param {*} tooltipId 
   */
  const handleTooltipClose = (tooltipId) => {
    if (isMobileOrTab) {
      setTooltipOpen(prevState => ({
        ...prevState,
        [tooltipId]: false
      }));
    }
  };

  /**
  * Tooltip Open Function
  * @param {*} tooltipId 
  */
  const handleTooltipOpen = (tooltipId) => {
    if (isMobileOrTab) {
      setTooltipOpen(prevState => ({
        ...prevState,
        [tooltipId]: true
      }));
    }
  };

  return (
    <>
      <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>Debt Amount</Typography>
      <Box className="payment-enter-investor">
        <CustomTextField
          onChange={handleChangeInvestAmount}
          value={investAmount}
          hiddenLabel
          placeholder="Amount"
          disabled={seniorDebtData?.status === CAPITAL_TYPE_STATUS.FUNDED}
          variant='filled'
          error={investAmountError.error}
          helperText={investAmountError.message}
          FormHelperTextProps={{ sx: { color: 'white' } }}
          fullWidth InputProps={{
            inputComponent: NumericFormatCustom,
            startAdornment: <InputAdornment position="start"><AttachMoney sx={{ color: '#EDE4C5' }} /></InputAdornment>,
            endAdornment: <InputAdornment position="end"><Button
              disabled={seniorDebtData?.status === CAPITAL_TYPE_STATUS.FUNDED}
              onClick={() => { setInvestAmount(formatDecimalNumber(parseFloat(seniorDebtData?.total_raise) - parseFloat(fundedAmount))); amountValidation(formatDecimalNumber(parseFloat(seniorDebtData?.total_raise) - parseFloat(fundedAmount))) }} className="rc-tertiary-btn btn-golden" style={{
                padding: '0px', height: '27px', marginRight: '11px'
              }} variant="filled"> max</Button> <Tooltip classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow', }} PopperProps={{ style: { maxWidth: '200px', maxHeight: '130px' } }} title={<span className='font-14'>You can submit dept amount between the minimum investment to the max loan amount requested</span>} placement='bottom' arrow open={isMobileOrTab ? tooltipOpen[4] : undefined}><Info style={{ color: 'white' }} onClick={() => handleTooltipOpen(4)}
                onMouseOver={isMobileOrTab ? undefined : () => handleTooltipOpen(4)}
                onMouseOut={isMobileOrTab ? undefined : () => handleTooltipClose(4)} /></Tooltip></InputAdornment>,
          }} sx={{ input: { color: '#94A3B8 !important' } }}
        // onInput={(event) => {
        //   event.target.value = event.target.value
        //     .replace(/[^\d.]/g, "")
        // }}
        />
      </Box>
    </>

  )
});


/**
 * Close Fund
 */
const FundsSpaceX = forwardRef(({ fundedAmount, totalFundedAmount, assetData, localChangeAmount, disableCondition, paymentMethod }, ref) => {

  //for now doing only equity type
  const [investAmount, setInvestAmount] = useState(0)
  const [investAmountError, setInvestAmountError] = useState({ error: false, message: '' })
  const [tooltipOpen, setTooltipOpen] = useState({ 1: false });
  const isMobileOrTab = useMediaQuery('(max-width: 1024px)');
  const [actualInvestAmount, setActualInvestAmount] = useState(0)
  const [isChange, setIsChange] = useState(false)

  // project amount
  const projectAmount = assetData?.fractionalize_total_price;
  const managementFees = assetData?.management_fees;
  const remainingAmount = parseFloat(projectAmount) - parseFloat(totalFundedAmount);

  const handleChangeInvestAmount = (event) => {
    let value = event.target.value
    setInvestAmount(value);
    amountValidation(value)
  };

  // Expose the child function through the ref
  useImperativeHandle(ref, () => ({
    validationCheck, getCapitalData
  }));

  useEffect(() => {
    localChangeAmount(investAmount);

    if (paymentMethod === 'crypto') {
      let newAmount = investAmount - investAmount * managementFees / 100
      setActualInvestAmount(newAmount);
    } else {
      setActualInvestAmount(investAmount);
    }
    if (investAmount > 0) {
      setIsChange(true)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investAmount, paymentMethod])

  useEffect(() => {
    if (isChange) {
      if (paymentMethod === 'crypto') {
        validationCheck((Math.ceil((100 / (100 - (managementFees ?? 0))) * 10000) / 10000) * assetData?.fractionalize_minimum_investment)
      } else {
        validationCheck(assetData?.fractionalize_minimum_investment)
      }
    }
  }, [investAmount, paymentMethod, isChange])

  const validationCheck = (newMin) => {
    let newMinimum = newMin;
    if (!newMinimum) {
      newMinimum = ((Math.ceil((100 / (100 - (managementFees ?? 0))) * 10000) / 10000) * assetData?.fractionalize_minimum_investment)
    }
    let validatedAmount = amountValidation(investAmount, newMinimum); // separated because need to run both
    return validatedAmount
  }

  const amountValidation = (amount = investAmount, newMin) => {
    let value = parseFloat(amount);
    let minimum_investment = parseFloat(assetData?.fractionalize_minimum_investment);

    let remainingAmount = (parseFloat(projectAmount) - parseFloat(totalFundedAmount) > 0 ? parseFloat(projectAmount) - parseFloat(totalFundedAmount) : 0)
    // remainingAmount = parseFloat(remainingAmount + (paymentMethod === 'crypto' ? parseFloat(remainingAmount * managementFees / 100) : 0))
    if (assetData?.is_sold) {
      setInvestAmountError({ error: true, message: 'Asset is already funded' })
      return false
    }
    else if (isNaN(value)) {
      setInvestAmountError({ error: true, message: 'Amount is required' })
      return false
    } else if (value === 0) {
      setInvestAmountError({ error: true, message: 'Amount Should be greater than zero' })
      return false
    } else if (value > remainingAmount) {
      setInvestAmountError({ error: true, message: `Amount should be less than or equal to $${formatNumber(remainingAmount)}` })
      return false
    } else if (parseFloat(value) < parseFloat(minimum_investment)) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      setInvestAmountError({
        error: true, message:
          `Minimum investment amount is $${formatNumber(parseFloat(minimum_investment))}`
      })
      return false
    } else { //no error
      setInvestAmountError({ error: false, message: '' })
      return true
    }
  }

  const getCapitalData = () => {
    return { offer_amount: parseFloat(investAmount) }
  }

  /**
   * Tooltip Close function
   * @param {*} tooltipId 
   */
  const handleTooltipClose = (tooltipId) => {
    if (isMobileOrTab) {
      setTooltipOpen(prevState => ({
        ...prevState,
        [tooltipId]: false
      }));
    }
  };

  /**
  * Tooltip Open Function
  * @param {*} tooltipId 
  */
  const handleTooltipOpen = (tooltipId) => {
    if (isMobileOrTab) {
      setTooltipOpen(prevState => ({
        ...prevState,
        [tooltipId]: true
      }));
    }
  };

  return (
    <>
      <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>You Pay</Typography>
      <Box className="payment-enter-investor">
        <CustomTextField onChange={handleChangeInvestAmount}
          value={investAmount}
          hiddenLabel
          placeholder="Amount"
          disabled={(assetData?.fractionalize_total_price <= totalFundedAmount || disableCondition) ? true : false}
          variant='filled'
          error={investAmountError.error}
          helperText={investAmountError.message}
          FormHelperTextProps={{ sx: { color: 'white' } }}
          fullWidth InputProps={{
            inputComponent: NumericFormatCustom,
            startAdornment: <InputAdornment position="start"><AttachMoney sx={{ color: '#EDE4C5' }} /></InputAdornment>,
            endAdornment: <InputAdornment position="end"><Button
              disabled={(assetData?.fractionalize_total_price <= totalFundedAmount || disableCondition) ? true : false}
              onClick={() => { setInvestAmount(remainingAmount > 0 ? remainingAmount : 0); amountValidation(remainingAmount > 0 ? remainingAmount : 0) }} className="rc-tertiary-btn btn-golden" style={{
                padding: '0px', height: '27px', marginRight: '11px'
              }} variant="filled"> max</Button>
              {/* <Tooltip classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow', }} PopperProps={{ style: { maxWidth: '200px', maxHeight: '130px' } }}
                title="" placement='bottom' arrow open={isMobileOrTab ? tooltipOpen[1] : undefined}
                onClose={() => handleTooltipClose(1)}><Info style={{ color: 'white' }}
                  onClick={() => handleTooltipOpen(1)}
                  onMouseOver={isMobileOrTab ? undefined : () => handleTooltipOpen(1)}
                  onMouseOut={isMobileOrTab ? undefined : () => handleTooltipClose(1)} /></Tooltip> */}
            </InputAdornment>,
          }} sx={{ input: { color: '#94A3B8 !important' } }}
        // onInput={(event) => {
        //   event.target.value = event.target.value
        //     .replace(/[^\d.]/g, "")
        // }}
        />

        <>
          <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>Your Investment Capital</Typography>
          <Box className="payment-enter-investor-ro">
            <CustomTextField hiddenLabel variant='filled' FormHelperTextProps={{ color: 'primary' }}
              fullWidth InputProps={{
                startAdornment: <InputAdornment position="start"><AttachMoney sx={{ color: '#6A91A9' }} /></InputAdornment>,
                readOnly: true,
              }}
              value={formatNumber(actualInvestAmount)}
              // defaultValue={investAmount}
              sx={{ input: { color: 'white !important' } }} />
          </Box>
        </>
      </Box>
    </>
  )
});