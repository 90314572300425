import React, { useEffect, useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import { useDispatch } from "react-redux";
import { Close, Done } from "@mui/icons-material";
import { Box, Button, Typography, IconButton, Modal, Backdrop, CircularProgress, Chip, Stepper, Step, StepLabel, StepContent } from "@mui/material";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import SnackbarAlert from "../Common/SnackbarAlert";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { GetApi, postApi } from "../../Api/Api";
import { CONTRACT_NAME, CAPITAL_TYPE_ID } from "../../constants";
import { update } from "../../features/auth/authSlice";
import { race } from "../../Web3/race-chain";

// smart contract methods
import * as methods from "./methods";  // Import all methods from a single file
import '../../Pages/ProjectProposer/Questionnaire/Components/ProposerStakeRaceTokens.css'
import { GetContractAddress } from "../Common/GetContractAddress";
import { GetTokenAddress } from "../Common/GetTokenAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";
import { calculateRemainingAmount } from "./calculateRemainingAmount";
import { formatNumber } from "../Common/USFormat";
// Actions grouped into a single object
const { checkBalance, checkAllowance, approveToken, payExitAmount } = methods;

// Main
export default function PayExitTransactionProgressModal(props) {
    const dispatch = useDispatch();
    const { chain } = useNetwork();

    const identity = props?.identity; // variable to identify the method type

    // Getting user wallet address
    const { address } = useAccount(); // wagmi (Hook) for accessing user account information

    // Wallet disconnect function
    const { showSnackbar } = useSnackbar(); // Hook for displaying snackbar or notifications

    // State variables initialization
    const [activeStep, setActiveStep] = useState(-1); // State variable for tracking active step in a progress modal
    const [associatedWallet, setAssociatedWallet] = useState(null); // State variable to hold associate wallet address in a progress modal
    const [isAssociated, setIsAssociated] = useState(null); // State variable to hold associate wallet address in a progress modal

    // State to check the token status
    const [TokenStatus, SetTokenStatus] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false)

    // Default steps for a process
    const [steps, setSteps] = useState([
        { label: 'Wallet Connected', description: `You have to connect your wallet.` }, // Step indicating that the wallet is connected
        { label: 'Confirm Transaction', description: `You need to pay a transaction fee.` } // Step waiting for transaction confirmation
    ]);

    const localData = JSON.parse(localStorage.getItem("user_data"));

    // monitor changes in wallet address
    useEffect(() => {
        // console.log("wallet address", address); // Logging wallet address when it changes
    }, [address]); // Dependency array to trigger effect on changes to 'address'

    let assetData = props?.propData; // Asset Data

    /**
     * Function to set the description text
     */
    useEffect(() => {
        if (identity === 'pay-exit-amount') {
            // Setting new steps for the process
            const newSteps = [{ label: "Wallet Connected", description: "You have to connect your wallet." }];
            if (assetData?.capital_info?.some((item) => item?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT)) {
                newSteps.push({ label: "Approve Tokens", description: "Confirmation to approve tokens." });
                newSteps.push({ label: "Paying to Senior", description: "You need to pay senior debt amount." });
            }
            if (assetData?.capital_info?.some((item) => item?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT)) {
                newSteps.push({ label: "Approve Tokens", description: "Confirmation to approve tokens." });
                newSteps.push({ label: "Paying to Junior", description: "You need to pay junior debt amount." });
            }
            if (assetData?.capital_info?.some((item) => item?.capital_type_id === CAPITAL_TYPE_ID.EQUITY)) {
                newSteps.push({ label: "Approve Tokens", description: "Confirmation to approve tokens." });
                newSteps.push({ label: "Paying to Equity", description: "You need to pay to equity." });
            }

            setSteps(newSteps);
        }
    }, [identity]);

    /**
     * Calling Api to check the Token Current Status
     */
    useEffect(() => {
        async function CheckToken() {
            try {
                const checkTokenRes = await GetApi("/user/checkToken")
                if (checkTokenRes?.data?.code === 200 && checkTokenRes?.data?.status) {
                    SetTokenStatus(checkTokenRes?.data)
                }
            } catch (error) {
                console.log(error);
            }
        }
        CheckToken();
    }, []);

    // smart contract -- start
    useEffect(() => {
        if (TokenStatus && parseFloat(chain?.id) === parseFloat(race?.id)) {
            if (TokenStatus?.status) {
                if (address) {
                    // Function to make transaction to blockchain
                    async function makeTransaction() {
                        try {
                            // Set active step to 1
                            setActiveStep(1);

                            let balanceOf = 0;
                            let tokenAddress = null;
                            let tokenAbi = null;
                            if (assetData?.feeTokenType) {
                                const responseToken = await GetTokenAddress(assetData?.contract_version, assetData?.feeTokenType);
                                if (responseToken && responseToken?.length) {
                                    tokenAddress = responseToken[0].address;
                                    tokenAbi = responseToken[0].abi;
                                }
                            }

                            // Set the allowance amount and spender contract address
                            let allowanceAmt = assetData?.amount;
                            let actualRemainingAmt = assetData?.amount;
                            let spenderAddress = null;
                            let contractAddress = null;
                            let contractAbi = null;

                            let contract_version = assetData?.contract_version;
                            const response1 = await GetContractAddress({ ...assetData }, contract_version, CONTRACT_NAME.SPV_DEBT_REPAYMENT);
                            if (response1 && response1?.length) {
                                contractAddress = response1[0].address;
                                contractAbi = response1[0].abi;
                                spenderAddress = response1[0].address
                            }

                            let isSenior = assetData?.capital_info?.some((item) => item?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT);
                            let isJunior = assetData?.capital_info?.some((item) => item?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT);

                            // get actual remaining amount for senior
                            let seniorRemainingAmt = 0;
                            if (assetData?.capital_info?.some((item) => item?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT)) {
                                const remainingAmtData = await calculateRemainingAmount(contractAddress, contractAbi, {
                                    ...assetData,
                                    isPayExit: true,
                                    capital_type_id: CAPITAL_TYPE_ID.SENIOR_DEBT
                                });
                                if (remainingAmtData?.length > 1) {
                                    seniorRemainingAmt = Math.ceil((Number(remainingAmtData[0].totalAmount) / 1e18) * 100) / 100;

                                    if (parseFloat(assetData?.amount) > seniorRemainingAmt) {
                                        actualRemainingAmt = parseFloat(assetData?.amount - seniorRemainingAmt)
                                    } else {
                                        actualRemainingAmt = 0;
                                    }
                                    if (seniorRemainingAmt == 0) {
                                        setActiveStep(3)
                                    } else {

                                        // check if allowance amount and spender address exist
                                        if (seniorRemainingAmt > 0) {
                                            // Fetching balance of connected wallet address
                                            balanceOf = await checkBalance(address, tokenAddress, tokenAbi)

                                            // Return and Exit if insufficient RACE/USDT Tokens balance in the wallet
                                            if ((balanceOf) < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                                showSnackbar(`Insufficient ${assetData?.feeTokenType ? assetData?.feeTokenType : 'USDT'} Tokens`, 'error');
                                                props?.handleModalClose();
                                                return
                                            }

                                            // Now check for allowance RACE/USDT Tokens
                                            const allowance = await checkAllowance(address, tokenAddress, tokenAbi, spenderAddress);

                                            // If not enough allowance available then, call approve method
                                            if ((allowance) < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                                const data = await approveToken(
                                                    address, // user wallet address
                                                    tokenAddress,
                                                    tokenAbi,
                                                    spenderAddress, // spender address
                                                    parseFloat(allowanceAmt), // allowance amount
                                                    assetData?.feeTokenType, // fee token type
                                                )
                                                if (data.status === "success") {
                                                    showSnackbar("Transaction Successful", 'success')
                                                    setActiveStep(2);
                                                } else {
                                                    props?.handleModalClose();
                                                    setActiveStep(-1)
                                                    showSnackbar("Transaction Failed", 'error')
                                                    return
                                                }
                                            }

                                            // Now check for new allowance RACE/USDT Tokens
                                            const newAllowance = await checkAllowance(address, tokenAddress, tokenAbi, spenderAddress);

                                            // Return and Exit if insufficient RACE/USDT Tokens allowance to spender contract
                                            if (newAllowance < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                                showSnackbar('Insufficient allowance', 'error');
                                                props?.handleModalClose();
                                                return
                                            }

                                            setActiveStep(2);
                                            let updatedArr = steps?.map((item, index) => index === 2 ? {
                                                label: item.label, description: `Senior Debt Remaining: ${formatNumber(seniorRemainingAmt)}, Remaining amount after paying to senior: ${formatNumber(actualRemainingAmt)}`
                                            } : item)
                                            setSteps(updatedArr)
                                            // Now call the method as required for senior
                                            await handlePayExitAmount(CAPITAL_TYPE_ID.SENIOR_DEBT, false, allowanceAmt);
                                            setActiveStep(3);
                                        }
                                    }
                                }
                            }

                            if (actualRemainingAmt === 0) {
                                setTimeout(() => {
                                    props?.handleModalClose();
                                    props?.confirmStake();
                                }, 1000);
                            }

                            let juniorRemainingAmt = 0;
                            if (assetData?.capital_info?.some((item) => item?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT) && (parseFloat(assetData.amount - seniorRemainingAmt) > 0)) {
                                const remainingAmtData = await calculateRemainingAmount(contractAddress, contractAbi, {
                                    ...assetData,
                                    isPayExit: true,
                                    capital_type_id: CAPITAL_TYPE_ID.JUNIOR_DEBT,
                                    amount: parseFloat(assetData.amount - seniorRemainingAmt)
                                });
                                if (remainingAmtData?.length > 1) {
                                    juniorRemainingAmt = Math.ceil((Number(remainingAmtData[0].totalAmount) / 1e18) * 100) / 100;

                                    if ((parseFloat(assetData?.amount) - parseFloat(seniorRemainingAmt)) > parseFloat(juniorRemainingAmt)) {
                                        actualRemainingAmt = parseFloat(assetData?.amount) - parseFloat(seniorRemainingAmt) - parseFloat(juniorRemainingAmt)
                                    } else {
                                        actualRemainingAmt = 0;
                                    }
                                    if (juniorRemainingAmt == 0) {
                                        setActiveStep(isSenior ? 5 : 3)
                                    } else {
                                        // check if allowance amount and spender address exist
                                        if (juniorRemainingAmt > 0) {
                                            allowanceAmt = parseFloat(allowanceAmt) - parseFloat(seniorRemainingAmt);
                                            // Fetching balance of connected wallet address
                                            balanceOf = await checkBalance(address, tokenAddress, tokenAbi)

                                            // Return and Exit if insufficient RACE/USDT Tokens balance in the wallet
                                            if ((balanceOf) < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                                showSnackbar(`Insufficient ${assetData?.feeTokenType ? assetData?.feeTokenType : 'USDT'} Tokens`, 'error');
                                                props?.handleModalClose();
                                                return
                                            }

                                            // Now check for allowance RACE/USDT Tokens
                                            const allowance = await checkAllowance(address, tokenAddress, tokenAbi, spenderAddress);

                                            // If not enough allowance available then, call approve method
                                            if ((allowance) < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                                const data = await approveToken(
                                                    address, // user wallet address
                                                    tokenAddress,
                                                    tokenAbi,
                                                    spenderAddress, // spender address
                                                    parseFloat(allowanceAmt), // allowance amount
                                                    assetData?.feeTokenType, // fee token type
                                                )
                                                if (data.status === "success") {
                                                    showSnackbar("Transaction Successful", 'success')
                                                    setActiveStep(isSenior ? 4 : 2);
                                                } else {
                                                    props?.handleModalClose();
                                                    setActiveStep(-1)
                                                    showSnackbar("Transaction Failed", 'error')
                                                    return
                                                }
                                            }

                                            // Now check for new allowance RACE/USDT Tokens
                                            const newAllowance = await checkAllowance(address, tokenAddress, tokenAbi, spenderAddress);

                                            // Return and Exit if insufficient RACE/USDT Tokens allowance to spender contract
                                            if (newAllowance < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                                showSnackbar('Insufficient allowance', 'error');
                                                props?.handleModalClose();
                                                return
                                            }

                                            setActiveStep(isSenior ? 4 : 2);
                                            let updatedArr1 = steps?.map((item, index) => index === (isSenior ? 4 : 2) ? {
                                                label: item.label, description: `Junior Debt Remaining: ${formatNumber(juniorRemainingAmt)}, Remaining amount after paying to junior: ${formatNumber(parseFloat(actualRemainingAmt))}`
                                            } : item)
                                            setSteps(updatedArr1)
                                            // Now call the method as required for senior
                                            await handlePayExitAmount(CAPITAL_TYPE_ID.JUNIOR_DEBT, false, allowanceAmt);
                                            setActiveStep(isSenior ? 5 : 3);
                                        }
                                    }
                                }
                            }

                            const response2 = await GetContractAddress({ ...assetData }, contract_version, CONTRACT_NAME.SPV_EQUITY_DISTRIBUTION);
                            if (response2 && response2?.length) {
                                contractAddress = response2[0].address;
                                contractAbi = response2[0].abi;
                                spenderAddress = response2[0].address
                            }

                            if (actualRemainingAmt === 0) {
                                setTimeout(() => {
                                    props?.handleModalClose();
                                    props?.confirmStake();
                                }, 1000);
                            }

                            allowanceAmt = actualRemainingAmt; // all remaining amount after paying to senior and junior goes to equity

                            // check if allowance amount and spender address exist
                            if (allowanceAmt > 0 && spenderAddress && assetData?.capital_info?.some((item) => item?.capital_type_id === CAPITAL_TYPE_ID.EQUITY)) {
                                balanceOf = await checkBalance(address, tokenAddress, tokenAbi)
                                // Return and Exit if insufficient RACE/USDT Tokens balance in the wallet
                                if ((balanceOf) < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                    showSnackbar(`Insufficient ${assetData?.feeTokenType ? assetData?.feeTokenType : 'USDT'} Tokens`, 'error');
                                    props?.handleModalClose();
                                    return
                                }

                                // Now check for allowance RACE/USDT Tokens
                                const allowance = await checkAllowance(address, tokenAddress, tokenAbi, spenderAddress);

                                // If not enough allowance available then, call approve method
                                if ((allowance) < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                    const data = await approveToken(
                                        address, // user wallet address
                                        tokenAddress,
                                        tokenAbi,
                                        spenderAddress, // spender address
                                        parseFloat(allowanceAmt), // allowance amount
                                        assetData?.feeTokenType, // fee token type
                                    )
                                    if (data.status === "success") {
                                        showSnackbar("Transaction Successful", 'success')
                                        setActiveStep((isJunior && isSenior) ? 6 : (isSenior || isJunior) ? 4 : 2);
                                    } else {
                                        props?.handleModalClose();
                                        setActiveStep(-1)
                                        showSnackbar("Transaction Failed", 'error')
                                        return
                                    }
                                }

                                // Now check for new allowance RACE/USDT Tokens
                                const newAllowance = await checkAllowance(address, tokenAddress, tokenAbi, spenderAddress);

                                // Return and Exit if insufficient RACE/USDT Tokens allowance to spender contract
                                if (newAllowance < getCurrencyTypeDecimal(assetData?.feeTokenType, allowanceAmt)) {
                                    showSnackbar('Insufficient allowance', 'error');
                                    props?.handleModalClose();
                                    return
                                }

                                setActiveStep((isJunior && isSenior) ? 6 : (isSenior || isJunior) ? 4 : 2);
                                let updatedArr1 = steps?.map((item, index) => index === ((isJunior && isSenior) ? 6 : (isSenior || isJunior) ? 4 : 2) ? {
                                    label: item.label, description: `Equity Amount: ${formatNumber(allowanceAmt)}`
                                } : item)
                                setSteps(updatedArr1)
                                // Now call the method as required for equity
                                await handlePayExitAmount(CAPITAL_TYPE_ID.EQUITY, true, allowanceAmt);
                                setActiveStep((isJunior && isSenior) ? 7 : (isSenior || isJunior) ? 5 : 3);
                            }

                            setTimeout(() => {
                                props?.handleModalClose();
                                props?.confirmStake();
                            }, 1000);

                            /**
                            * handle pay prepayment amount
                            */
                            async function handlePayExitAmount(capital_type_id, is_last, amount) {
                                await payExitAmount(address, contractAddress, contractAbi, tokenAddress, { ...assetData, amount: amount }, props?.propData?.user_id, props?.propData?.asset_id, showSnackbar, handleActiveStep, props?.handleModalClose, props?.confirmStake, capital_type_id, is_last);
                            }
                        } catch (error) {
                            console.log('error', error)
                            if (error?.cause?.reason && error?.cause?.reason?.split(":")?.length) {
                                let reason = error.cause.reason;
                                let message = reason?.split(":")
                                showSnackbar(message[1] ? message[1] : message[0], 'error')
                                props?.handleModalClose();
                            } else {
                                showSnackbar("Transaction Failed", 'error')
                                props?.handleModalClose();
                            }
                        }
                    }
                    // Function to check associate wallet with the account
                    async function checkAssociatedWallet() {
                        try {
                            // const checkRes = await postApi(`/user/checkWallet`, { wallet_address: address?.toLowerCase(), user_id: localData?.id });
                            const checkRes = await postApi(`/user/checkWallet`, { user_id: localData?.id });
                            if (checkRes?.data?.status && checkRes?.data?.data?.wallet_address?.toLowerCase() === address?.toLowerCase()) {
                                setActiveStep(0);
                                setAssociatedWallet(address);
                                makeTransaction();
                                handleCloseDialog();
                            } else if (checkRes?.data?.data?.wallet_address) {
                                handleCloseDialog();
                                setActiveStep(0);
                                showSnackbar('This wallet is not associated with the account', 'error');
                            } else {
                                handleCloseDialog();
                                setActiveStep(0);
                                setTimeout(() => {
                                    // disconnect();
                                    // props?.handleModalClose();
                                }, 1000)
                                return
                            }
                        } catch (error) {
                            showSnackbar('There was an error', 'error');
                        }
                    }
                    // Call the function checkAssociatedWallet
                    checkAssociatedWallet()
                } else {
                    setActiveStep(0);
                }
            } else {
                setOpenSnackbar(true)
                setTimeout(() => {
                    window.location.href = '/';
                }, 4000);
                handleCloseDialog()
            }
        }

    }, [address, TokenStatus, chain, isAssociated])
    //  smart contract -- end

    /**
     * Set the active step value
     * @param {Number} value 
     */
    const handleActiveStep = (value) => {
        setActiveStep(value)
    }

    //Wallet connection code start
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);

    /**
     * Function to handle wallet modal open
     */
    const handleClickOpen = async () => {
        setWalletDialogOpen(true);
    };

    /**
     * Function to handle wallet modal close
     */
    const handleCloseDialog = () => {
        setWalletDialogOpen(false);
    };

    /**
     * gets called when a wallet is connect successfully
     * @param {*} acc
     */
    const walletLogin = async (acc, isAssociate) => {
        dispatch(update({ wallet_address: acc?.toLowerCase() }));
        setAssociatedWallet(acc?.toLowerCase())
        if (isAssociate)
            setIsAssociated(true)
    };
    //Wallet connection code end

    /**
     * Function to  handle custom icons
     * @returns custom icons for progress steps
     */
    const CustomStepIcon = ({ active, completed, index }) => {
        if (completed) {
            // You can customize the completed state icon here
            return <Chip label={<Done />} color="primary" className="item-completed" size="small" />;
        }

        // Customize the active state icon with CircularProgress loader
        return active ? <CircularProgress color="inherit" size={20} /> :
            <Chip label={index + 1} color="primary" className="item-indexing" size="small" />; // Change the icon based on your preference
    };

    /**
     * Function to handle modal close
     * @param {Event} reason 
     * @returns 
     */
    const handleWalletTransactionModal = (reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown' || reason?.code === "Escape") {
            return; //do nothing basically do not close modal
        } else {
            props?.handleModalClose({ warning: true });
        }
    };

    /**
    * handles snackbar close
    * @param {*} event 
    * @param {*} reason 
    * @returns 
    */
    const handleClose = (event, reason) => {
        setOpenSnackbar(false);
    };

    return (
        <>
            {/* Stepper modal for transaction flow for abi*/}
            <Modal
                open={props?.openTransactionModal}
                onClose={handleWalletTransactionModal}
                className="kyc-modal wallet-transaction-flow"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                BackdropComponent={Backdrop}
                BackdropProps={{ open: false }}
                sx={{ backdropFilter: 'blur(2px)' }}
            >
                <Box className="set-stack-rts common-modal-design">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                        {/* {activeStep >= steps?.length - 1 ? */}
                        {activeStep > 0 ?
                            <></>
                            :
                            <IconButton >
                                <Close style={{ color: '#fff' }} onClick={handleWalletTransactionModal} />
                            </IconButton>
                        }
                    </Box>
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps?.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} index={index} />}>
                                        {!associatedWallet && index === 0 ? <Button className="btn-rounded" onClick={handleClickOpen}>Connect Wallet</Button> : index === 0 ? <Button className="btn-rounded btn-green-400">Wallet connected</Button> : step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography className={associatedWallet && index === 0 ? "wallet-address-text" : ''}>{associatedWallet && index === 0 ? associatedWallet : step.description}</Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Box>
            </Modal >

            <SnackbarAlert open={openSnackbar} message={"Your Token has been expire , please login again"} severity={"info"} onClose={handleClose} />

            {/* wallet connect component */}
            <ConnectWalletInvestor
                open={walletDialogOpen}
                handleCloseDialog={handleCloseDialog}
                handleConnectedSuccess={walletLogin}
                closeTransactionModal={props?.handleModalClose}
            />
        </>
    );
}
