import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles"
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Chip, Box, Paper, InputBase, TableRow, TableHead, TableContainer, Table, TableBody, Typography, MenuItem, ListItemText, ImageListItem, FormControl, InputLabel, Select, Stack, Grid, Modal, IconButton } from '@mui/material';
import { ImageOutlined, RemoveRedEye, Edit, Download, SwapVert, Search, ViewComfy } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import FileViewer from "react-file-viewer";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { GetApiParam } from '../../../Api/Api';
import config from '../../../Config/Config';
import "../../../Pages/Transactions/Transactions.css"
import CommonBackdropLoader from '../../../Components/Common/CommonBackdropLoader';
import { getLoggedInUserType } from '../../../Services/localstorage.service';
import { formatDate } from '../../../Utils/utils';
import { getUserDataFromLocalStorage } from '../../../Services/localstorage.service';
import InvoiceViewerSp from '../MyJobs/InvoiceViewer/InvoiceViewer'
import InvoiceViewerClient from '../../ClientDashboard/MyOrders/InvoiceViewer/InvoiceViewer'

// Styles for table columns
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

// Styles for table rows
const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));

const ContractInvoice = ({ searchString }) => {
    const [loading, setLoading] = useState(false);
    const [documentsData, setDocumentsData] = useState([]);
    const [contractData, setContractData] = useState([]);
    const [sortBy, setSortBy] = useState('Default');
    const [view, setView] = useState(false);
    const [file, setFile] = useState("");
    const [type, setType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ itemsPerPage, setItemsPerPage ] = useState(5);
    const [ paginationData, setPaginationData ] = useState({
        "page": 1,
        "pageSize": 0,
        "totalCount": 0,
        "totalPages": 0
    });

    let User = getUserDataFromLocalStorage();

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const loggedInUserType = getLoggedInUserType();

    /**
     * here we get documents list
     */
    const getContractDocument = async () => {
        setLoading(true);
        let payload = {
            tab: `contract`,
            page: currentPage,
            rowperpage: itemsPerPage,
            searchString: searchString
        }
        if(loggedInUserType === 'spv'){
            payload = {
                ...payload,
                spv_id: User?.id,
            }
        }
        if(loggedInUserType === 'proposer'){
            payload = {
                ...payload,
                client_id: User?.id,
            }
        }
        
        let res = await GetApiParam(`/services/getContract`, payload);
        if (res?.data?.data) {
            console.log("res.data.data---",res.data.data);
            setDocumentsData([]);
            setTimeout(() => {
                setDocumentsData(res.data.data.length > 0 ? res.data.data : []);
                setLoading(false);
            }, 1000);
            setPaginationData(res.data.pagination)
        }else{
            setDocumentsData([]);
            setLoading(false);
        }
    }
    useEffect(() => {
        getContractDocument();
    }, [currentPage]);

    useEffect(() => {
        if(currentPage !== 1)setCurrentPage(1);
        else getContractDocument();
    }, [searchString]);


    /**
     * open modal to display doc content
     * @param {Array} documentdata 
     * @returns {void}
     */
    const viewDoc = async (documentdata) => {
        setContractData(documentdata);
        setOpenModal(true);
        setView(true);
        if (documentdata?.path) {
            // const fileName = `${process.env.REACT_APP_IMAGE_URL}${documentdata.path ? documentdata.path : ""}`;
            // setFile(fileName);
            const fileName = await fetch(`${process.env.REACT_APP_IMAGE_URL}${documentdata.path ? documentdata.path : ""
                }`);

            const templateBuffer = await fileName.arrayBuffer();
            const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
            const templateUrl = URL.createObjectURL(templateBlob);
            setFile(templateUrl);
        }
        if (documentdata?.type === 'document' || documentdata?.type === 'doc') {
            setType('pdf');
        } else {
            setType(documentdata?.type);
        }
    };

    /**
     * handle modal close
     */
    const handleClose = () => { setOpenModal(false); setView(false) };

    // modal styles
    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };

    return (
        <>
        <CommonBackdropLoader loading={loading}/>
            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            {/* <StyledTableCell>Document Name</StyledTableCell> */}
                            <StyledTableCell align="left">Project Name</StyledTableCell>
                            <StyledTableCell align="center">Date</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentsData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.original_name}`}>
                                {/* <StyledTableCell component="th" scope="row">{row.original_name}</StyledTableCell> */}
                                <StyledTableCell align="left">  {row?.title}</StyledTableCell>
                                <StyledTableCell align="center">{formatDate(row.createdAt, 'MMM d, yyyy')}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Chip
                                        sx={{
                                            marginTop: 2,
                                            marginBottom: 2,
                                            color: "#13202D",
                                            bgcolor: config?.CONTRACT_STATUS?.CONTRACT_STATUS_COLOR[config.CONTRACT_STATUS[row.status]],
                                            borderRadius: "8px",
                                        }}
                                        label={config?.CONTRACT_STATUS[row.status]}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <IconButton>
                                        <RemoveRedEye onClick={() => viewDoc(row)} style={{ color: "#FFFFFF" }} />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {paginationData?.totalCount > 0 ?
                  <Box className="pagination-container">
                    <Pagination
                      count={paginationData.pageSize === 'all' ? 1 : Math.ceil(paginationData?.totalCount/Number(paginationData.pageSize))}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  </Box>:  <Typography className="font-14 font-readexpro" display={'flex'} justifyContent={'center'} color={'#9CA3AF'} mt={1}>
                      Record not available
                </Typography> }
            </TableContainer>
            {/* modal code start from here */}
            <Grid>
                {loggedInUserType === 'spv' && <InvoiceViewerSp contractData={contractData} isOpen={view} setIsOpenModal={setView} />}
                {loggedInUserType === 'proposer' && <InvoiceViewerClient contractData={contractData} isOpen={view} setIsOpenModal={setView} />}
            </Grid>
            {/* modal code end from here */}
        </>
    )
}

export default ContractInvoice