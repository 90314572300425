import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import { Box, Tab, Tabs, Grid, Typography, InputBase, Paper, IconButton, Stack } from "@mui/material";

// import custom components
import PendingDocumentsPanel from "../../../Pages/Documents/PendingDocumentsPanel";
import ContractDocument from "./ContractDocument";
import ReportsPanel from "../../../Pages/Documents/ReportsPanel"
import "../../../Pages/Transactions/Transactions.css"
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from "@mui/icons-material/Search";

import { Search } from "@mui/icons-material";
import ContractInvoice from "./ContractInvoice";

/**
 * TabPanel functional component that conditionally renders its children based on the active tab.
 * @param {Object} props - The properties passed to the component.
 * @param {ReactNode} props.children - The content to be rendered within the TabPanel.
 * @param {number} props.value - The current active tab index.
 * @param {number} props.index - The index of the specific TabPanel.
 * @param {Object} other - Additional properties spread onto the component.
 * @returns {JSX.Element} - The rendered TabPanel component.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

/**
 * Helper function to generate accessibility properties for tabs.
 * @param {number} index - The index of the tab.
 * @returns {Object} - Accessibility properties for the tab.
 */
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function Documents() {
  // Importing 'useTheme' hook from the Material-UI library
  const theme = useTheme();

  // State to manage the current tab
  const [value, setValue] = useState(0);
  const [searchString, setSearchString] = useState("");

  /**
   * Function to handle changes in the tab, triggered by the TabPanel
   * @param {Number} newValue - index value of tab panel
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Function to handle changes in the index, used for tab navigation
   * @param {Number} index
   */
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Box className="document-main-div mydocumentmain">
        <Grid className="transactions-list-head" item xs={10} sm={10} md={10}>
          <Box className="my-document-container">
          <Grid item md={7} xs={12} alignSelf={"center"}>
              <Typography variant="h3" className="font-22">
                My Documents
              </Typography>
            </Grid>
            <Grid item md={5} xs={12}>
              <Box className="ctSearch">
                <Paper className="ctPaper" elevation={0}>
                  <IconButton
                    type="button"
                    className="ctSearch-icon"
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    className="ctSearch-input"
                    placeholder="Search documents"
                    inputProps={{
                      "aria-label": "Search documents",
                    }}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                </Paper>
              </Box>
            </Grid>
          </Box>
          <Box className="transactions-list-head-inside">
            <Box className="tab-div service-pro-tab">
              <Tabs
                onChange={handleChange}
                value={value}
                aria-label="Tabs where each tab needs to be selected manually"
                sx={{ overflowX: "auto" }}
              >
                <Tab
                  label="Contracts"
                  className={
                    value === 0
                      ? "tabs transactions-active-button"
                      : " tabs transactions-inactive-button"
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label="Invoices"
                  className={
                    value === 1
                      ? "tabs transactions-active-button"
                      : " tabs transactions-inactive-button"
                  }
                  {...a11yProps(1)}
                />
                {/* <Tab
                  label="Tax Documents"
                  className={
                    value === 2
                      ? "tabs transactions-active-button"
                      : " tabs transactions-inactive-button"
                  }
                  {...a11yProps(2)}
                /> */}
              </Tabs>
              {/* <Box className="sort-by-container">
                    <Stack flexDirection={'row'} gap={2}>
                      <Stack flexDirection={'row'} gap={1}>
                        <SortIcon className="sp-sortby"/>
                        <Typography className="sp-sortby">Sort by</Typography>
                      </Stack >
                      <Stack flexDirection={'row'} gap={1}>
                        <FilterListIcon className="sp-sortby"/>
                        <Typography className="sp-sortby">Sort by</Typography>
                      </Stack >
                    </Stack>
                </Box> */}
            </Box>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0}>
                <ContractDocument searchString={searchString}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ContractInvoice searchString={searchString}/>
                {/* <ReportsPanel /> */}
              </TabPanel>
              {/* <TabPanel value={value} index={2}>
                <ReportsPanel />
              </TabPanel> */}
            </SwipeableViews>
          </Box>
        </Grid>
      </Box>
    </>
  );
}
