import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Box, Button, Chip, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Tab, Tabs, Typography } from '@mui/material'
import PropTypes from "prop-types";
import { useLocation } from 'react-router-dom';
import ConnectWalletInvestor from '../../../Web3/ConnectWalletInvestor';
import { update } from '../../../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux';
import { Cancel, Check, Upload } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import "../Investor.css"
import { CircleCheckIcon } from '../../../Components/Images/Images';
import { SUB_CATEGORY_TYPE_ID } from '../../../constants';

export default function PaymentMethod({ assetCategoryName, currencyOption, setFeeTokenType, setPaymentMethod, setUploadedDocumentFiat, asset_sub_category }) {
    // Importing necessary hooks and functions
    const dispatch = useDispatch();
    const location = useLocation();
    const userData = useSelector((state) => state.auth.user);

    // Initialize hooks with default values for managing uploaded, deleted, and file documents
    const [uploadedDocuments, setUploadedDocuments] = useState([]);

    // Determine whether to show the fiat tab based on the asset category
    // const showFiat = assetCategoryName === "funds" && asset_sub_category && asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? true : false;
    const showFiat = false;

    // State variable for controlling the selected tab value
    const [value, setValue] = React.useState(showFiat == true ? 0 : 1);

    // state to hold payment currency type
    const [selectedOption, setSelectedOption] = useState('USDT');

    /**
    * Handle change in payment type
    * @param {Event} event 
    */
    const handleOptionChange = (event) => { setSelectedOption(event.target.value); setFeeTokenType(event.target.value); };

    /**
     * Function to handle tab changes
     * @param {*} event 
     * @param {*} newValue 
     */
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        if (setPaymentMethod && assetCategoryName === "funds") {
            setPaymentMethod(newValue == 0 ? 'fiat' : 'crypto')
        } else {
            setPaymentMethod('crypto')
        }
    }

    // useEffect to update the selected tab value based on the asset category and showFiat variable
    useEffect(() => {
        setValue(showFiat == true ? 0 : 1)
        if (setPaymentMethod && assetCategoryName === "funds") {
            setPaymentMethod(showFiat == true ? 'fiat' : 'crypto')
        } else {
            setPaymentMethod('crypto')
        }
    }, [assetCategoryName])


    //Wallet code
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);

    /**
     * @description opens wallet connection dialog
     */
    const handleClickOpen = () => {
        setWalletDialogOpen(true);
    };

    /**
     *  @description closes wallet connection dialog
     * @param {string} [value] 
     */
    const handleCloseDialog = (value) => {
        setWalletDialogOpen(false);
    };

    /**
     * @description gets called when a wallet is connected successfully 
     * @param {*} acc 
     */
    const walletLogin = async (acc) => {
        dispatch(update({ wallet_address: acc }));
    }
    //Wallet code ends

    /**
     * Function to handle uploaded documents. Filters only PDF files and updates the 'uploadedDocuments' state.
     * @param {Array} acceptedFiles - Array of accepted files.
     */
    const onDrop = useCallback(acceptedFiles => {
        const pdfFilesArray = acceptedFiles.filter(file => file.type === 'application/pdf');
        // let dataDetail = [...uploadedDocuments, ...pdfFilesArray];
        setUploadedDocuments(pdfFilesArray);
        setUploadedDocumentFiat(pdfFilesArray);
    }, [uploadedDocuments]);

    // useDropzone hook configuration to handle file drop
    const { getRootProps, open, getInputProps } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: {
            "application/pdf": [".pdf"],
        },
        multiple: false,
        onDrop
    });
    /**
* Function to handle deleted documents. If in edit mode, updates the 'deletedDoc' state for existing documents, and updates 'docFiles'.
* @param {Object} file - The file to be deleted.
*/
    const handleDeleteImage = (file) => {
        // Filter out the deleted image from the 'uploadedDocuments' state
        const newUploadedImages = uploadedDocuments.filter((image) => image.path !== file.path);
        setUploadedDocuments(newUploadedImages);
    };

    // preparing to show uploaded docs
    const files = uploadedDocuments?.map((file) => (
        <Box className="showUploadedDocuments">
            <Box key={file.path} className="showUploadedDocuments-wrap">
                <Box className="fileName">
                    {file?.path?.includes("proposer/uploads/") || file?.path?.includes("https://race25-live-uploads.s3.eu-central-2.amazonaws.com/") || file?.path?.includes("https://race25-uploads-docs.s3.eu-central-2.amazonaws.com/") || file?.path?.includes('https://local-racetest-images.s3.eu-central-2.amazonaws.com/') ? file.original_name : file.name}
                </Box>
                <Cancel onClick={() => handleDeleteImage(file)} className="removeIcon" />
            </Box>
        </Box >
    ))

    return (
        <>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }} className='rc-basic-container'>
                <Typography variant='h6' className='font-16' mb={1.5}>Payment Method</Typography>
                <Typography className='rc-small-secondary-text' variant='body2'>{asset_sub_category && asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? "Choose payment method to fund the transaction" : "Manage payment methods connected to your account"}</Typography>
                <Box sx={{ mt: 1 }} >
                    <Tabs className='rc-payment-tab-wrapper' value={value} onChange={handleTabChange} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} variant="fullWidth" >
                        {(assetCategoryName === "funds" && asset_sub_category && asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS && showFiat) &&
                            <Tab className='rc-payment-tab' label="Fiat" value={0} {...a11yProps(0)} disableRipple />
                        }
                        <Tab className='rc-payment-tab' label="Crypto" value={1} {...a11yProps(1)} disableRipple />
                    </Tabs>
                </Box>
                <Box ml={-1}>
                    <TabPanel value={value} index={0}>
                        <Typography mb={4} className='rc-small-secondary-text' gutterBottom>{assetCategoryName === "funds" ? "Pay using a bank account (Bank Transfer/ Wire Instruction is be available in the investment package)" : "Pay using a bank account or your credit card:"}</Typography>
                        {/* <Button className='btn-rounded btn-text-white btn-blue-500' size='small' variant='contained' >Connect to Payment Provider</Button> */}
                        {
                            asset_sub_category && asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS && showFiat ?
                                <Box mt={3}>
                                    <Typography className='rc-small-secondary-text' variant='body2'>Upload the transaction confirmation to complete the investment process</Typography>
                                    <Box mt={1}>
                                        <Box  {...getRootProps({ className: "dropzone" })}>
                                            <input {...getInputProps()} />
                                            <Box className='upload-box-box-ts'>
                                                <Box sx={{ marginRight: '10px' }}>
                                                    <Button className='btn-rounded btn-blueGray-200' onClick={open} startIcon={<Upload />}>
                                                        Browse Files
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {files !== "" ? (
                                            <Box sx={{ paddingBottom: "16px", overflow: "hidden", margin: "10px 0px" }}>
                                                {files?.map((fileName, ind) => {
                                                    return (<Box display={"flex"} alignItems={"center"} mt={1} key={ind}>
                                                        <Avatar src={CircleCheckIcon} alt="Icon" sx={{ width: 24, height: 24 }} />
                                                        <Box className="dD-file-name text-white" ml={1}>
                                                            {fileName}
                                                        </Box>
                                                    </Box>)
                                                })}
                                            </Box>
                                        ) : (
                                            ""
                                        )}
                                    </Box>
                                </Box>
                                : null
                        }
                        <Typography mt={3} className='rc-small-secondary-text' gutterBottom>RACE does not process or store your bank account or credit card information.</Typography>

                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {/* Pay using RACE, BTC, ETH, USDT or USDC: */}
                        {currencyOption ?
                            <Box className='investor-select-currency-type'>
                                <Typography className='pay-using font-14'>
                                    Pay using
                                </Typography>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="payment-method"
                                        name="payment-method"
                                        defaultValue="USDT"
                                        row
                                        className='currency-options'
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        {/* <FormControlLabel
                                            value="RACE"
                                            control={<Radio />}
                                            label="RACE Token"
                                        /> */}
                                        <FormControlLabel
                                            value="USDT"
                                            control={<Radio />}
                                            label="USDT"
                                        />
                                        <FormControlLabel
                                            value="USDC"
                                            control={<Radio />}
                                            label="USDC"
                                        />
                                        <FormControlLabel
                                            value="DAI"
                                            control={<Radio />}
                                            label="DAI"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            :
                            <Typography mb={4} className='rc-small-secondary-text' gutterBottom>Pay using RACE, BTC, ETH, USDT or USDC:
                            </Typography>
                        }
                        {
                            userData?.wallet_address && userData.wallet_address !== "" ?
                                <Chip icon={<Check />} label={`Wallet : ${userData?.wallet_address}`} color="success" />
                                :
                                <Button onClick={handleClickOpen} className='btn-rounded btn-text-white btn-blue-500 ' size='small' variant='contained' >Connect to your Wallet</Button>

                        }
                        <Typography mt={3} className='rc-small-secondary-text' gutterBottom>RACE does not process or store your wallet information.</Typography>
                    </TabPanel>
                </Box>
            </Paper>
            <ConnectWalletInvestor open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} askConfirmation={true} />
        </>
    )
}



function a11yProps(index) {
    return {
        id: `horizontal-tab-${index}`,
        "aria-controls": `horizontal-tabpanel-${index}`
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number,
    value: PropTypes.number,
};

PaymentMethod.propTypes = {
    /**
     * @description asset category name is the `url` field of the api asset category
     */
    assetCategoryName: PropTypes.string,
};
