import React, { useState, useEffect } from "react";
import { NewLogo } from "../../Components/Images/Images";
import { Box, Modal, Avatar, Typography, Button, Stack, TextField, FormControl, RadioGroup, FormControlLabel, Radio, Grid } from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE, OFFER_STATUS } from '../../constants.js'
import NumericFormatCustomDollar from "../../Components/Common/NumericFormatCustomDollar";
import NumericFormatCustom from "../../Components/Common/NumericFormatCustom";
import NumericFormatCustomPercent from "../../Components/Common/NumericFormatCustomPercent";
import { GetApiParam, postApi } from "../../Api/Api";

const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "100%",
    maxWidth: "800px",
};

export default function OfferEditCancelModel({ open, offerDetails, onClose, saveChanges, setFeeTokenType }) {

    const [viewTable, setViewTable] = useState([]);
    const [EditMode, setEditMode] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);

    // state to hold payment currency type
    const [selectedOption, setSelectedOption] = useState('USDT');

    /**
    * Handle change in payment type
    * @param {Event} event 
    */
    const handleOptionChange = (event) => { setSelectedOption(event.target.value); setFeeTokenType(event.target.value); };

    useEffect(() => {
        let investType = offerDetails?.asset_data?.investment_type?.investment_name;
        let category_id = offerDetails?.asset_data?.category_id;

        async function fetchData() {
            try {
                // getting response from api
                const res = await postApi("/proposer/assetsDetails", { "asset_id": offerDetails?.asset_id });

                const suggestionRes = await GetApiParam("/committee/getSuggestReview", { "asset_id": offerDetails?.asset_id });

                const isSuggestion = suggestionRes?.data?.data?.length
                const SuggestionVal = suggestionRes?.data?.data?.length ? suggestionRes?.data?.data[0] : []

                if (res?.data?.data) {
                    let assetData = res?.data?.data[0];
                    if (investType === INVESTMENT_TYPE.LOAN) {
                        let alreadyFundedAmount = 0;
                        await GetApiParam('/invest/getAssetFundedAmount', { "asset_id": offerDetails?.asset_id }).then((res) => {
                            if (res?.data?.code === 200) {
                                alreadyFundedAmount = res?.data?.totalAmount;
                            }
                        })
                        let amount = parseFloat((assetData?.loan_amount) - (alreadyFundedAmount)).toFixed(2);

                        setViewTable([
                            {
                                title: 'Loan Amount',
                                key: 'offer_amount',
                                value: offerDetails?.offer_amount,
                                type: 'amount',
                                placeholder: "Enter loan amount",
                                check: amount,
                                minCheck: isSuggestion ? parseFloat(SuggestionVal?.minimum_investment) : (assetData?.loan_minimum_investment) ? parseFloat(assetData?.loan_minimum_investment) : 1
                            },
                            {
                                title: 'Loan Duration',
                                key: 'loan_duration',
                                value: offerDetails?.loan_duration,
                                type: 'duration',
                                placeholder: "Enter loan duration (months)",
                                check: isSuggestion ? parseFloat(SuggestionVal.loan_duration_to) : assetData?.loan_duration_month,
                                minCheck: isSuggestion ? parseFloat(SuggestionVal.loan_duration_from) : (assetData?.loan_iop ? parseFloat(assetData?.loan_iop + 1) : 1)

                            },
                            {
                                title: 'Interest Rate',
                                key: 'loan_roi',
                                value: offerDetails?.loan_roi,
                                type: 'rate',
                                placeholder: "Enter interest rate",
                                check: isSuggestion ? parseFloat(SuggestionVal.loan_roi_to) : assetData?.loan_roi,
                                minCheck: isSuggestion ? parseFloat(SuggestionVal.loan_roi_from) : 1
                            }
                        ]);
                    } else if (investType === INVESTMENT_TYPE.SALE) {
                        setViewTable([
                            {
                                title: 'Offer Amount',
                                key: 'offer_amount',
                                value: offerDetails?.offer_amount,
                                type: 'amount',
                                placeholder: "Enter offer amount",
                                check: assetData?.sale_price,
                                minCheck: parseFloat(assetData?.sale_deposit_amount) + 1
                            }
                        ]);
                    } else if (investType === INVESTMENT_TYPE.FRACTION && category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                        if (offerDetails.capital_type_id === CAPITAL_TYPE_ID.EQUITY) {
                            let getData = assetData?.capital_info?.filter((item) => item?.capital_type.id === offerDetails?.capital_type_id)
                            setViewTable([
                                {
                                    title: 'Offer Amount',
                                    key: 'offer_amount',
                                    value: offerDetails?.offer_amount,
                                    type: 'amount',
                                    placeholder: "Enter offer amount",
                                    check: getData[0]?.total_raise,
                                    minCheck: 1
                                },
                                {
                                    title: 'Equity',
                                    key: 'equity_percent',
                                    value: parseFloat(offerDetails?.equity_percent),
                                    type: 'rate',
                                    placeholder: "Enter equity",
                                    check: getData[0]?.equity,
                                    minCheck: 1
                                }
                            ]);
                        } else if (offerDetails.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT && category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                            let getData = assetData?.capital_info?.filter((item) => item?.capital_type.id === offerDetails?.capital_type_id)
                            setViewTable([
                                {
                                    title: 'Offer Amount',
                                    key: 'offer_amount',
                                    value: offerDetails?.offer_amount,
                                    type: 'amount',
                                    placeholder: "Enter offer amount",
                                    check: getData[0]?.total_raise,
                                    minCheck: 1
                                },
                                {
                                    title: 'Duration',
                                    key: 'duration',
                                    value: offerDetails?.loan_duration,
                                    type: 'duration',
                                    placeholder: "Enter loan duration (months)",
                                    check: getData[0]?.junior_duration_to ? getData[0]?.junior_duration_to : getData[0]?.junior_duration,
                                    minCheck: getData[0]?.junior_duration_from ? getData[0]?.junior_duration_from : 1
                                },
                                {
                                    title: 'Interest Rate',
                                    key: 'fractional_ror',
                                    value: offerDetails?.loan_roi,
                                    type: 'rate',
                                    placeholder: "Interest Rate",
                                    check: getData[0]?.junior_ror_to ? getData[0]?.junior_ror_to : getData[0]?.rate_of_return,
                                    minCheck: getData[0]?.junior_ror_from ? getData[0]?.junior_ror_from : 1
                                }
                            ]);

                        } else if (offerDetails.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT && category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                            let getData = assetData?.capital_info?.filter((item) => item?.capital_type.id === offerDetails?.capital_type_id)
                            setViewTable([
                                {
                                    title: 'Offer Amount',
                                    key: 'offer_amount',
                                    value: offerDetails?.offer_amount,
                                    type: 'amount',
                                    placeholder: "Enter offer amount",
                                    check: getData[0]?.total_raise,
                                    minCheck: 1
                                },
                                {
                                    title: 'Duration',
                                    key: 'duration',
                                    value: offerDetails?.loan_duration,
                                    type: 'duration',
                                    placeholder: "Enter loan duration (months)",
                                    check: getData[0]?.senior_duration_to ? getData[0]?.senior_duration_to : getData[0]?.senior_duration,
                                    minCheck: getData[0]?.senior_duration_from ? getData[0]?.senior_duration_from : 1
                                },
                                {
                                    title: 'Interest Rate',
                                    key: 'fractional_ror',
                                    value: offerDetails?.loan_roi,
                                    type: 'rate',
                                    placeholder: "Interest Rate",
                                    check: getData[0]?.senior_ror_to ? getData[0]?.senior_ror_to : getData[0]?.rate_of_return,
                                    minCheck: getData[0]?.senior_ror_from ? getData[0]?.senior_ror_from : 1
                                }
                            ]);

                        }

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [offerDetails]);

    // handle change in data
    const handleChange = (title, e) => {
        const updatedViewTable = viewTable.map(item => {
            // Check if the title matches the item you want to update
            if (item.title === title) {
                return { ...item, value: e.target.value };
            } else {
                return item;
            }
        });
        if (updatedViewTable?.every((item) => parseFloat(item.value) > 0 && item.value != "" && parseFloat(item.value) <= parseFloat(item.check) && parseFloat(item.value) >= parseFloat(item.minCheck))) {
            setErrorMsg(false)
        } else {
            setErrorMsg(true)
        }
        setViewTable(updatedViewTable);
    }

    // handle save changes 
    const handleChangedDataSubmit = () => {

        if (viewTable?.every((item) => parseFloat(item.value) > 0 && item.value != "" && parseFloat(item.value) <= parseFloat(item.check) && parseFloat(item.value) >= parseFloat(item.minCheck))) {
            saveChanges(offerDetails.asset_id, viewTable, 'Edited', offerDetails.id, offerDetails.investment_type_id, offerDetails, offerDetails?.asset_data?.category_id);
            onClose();
        } else {
            setErrorMsg(true)
        }
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            className="kyc-modal"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Box className="modal-body" sx={style}>
                <Box className="headContent">
                    <Box className="logo">
                        <Avatar
                            alt="Logo"
                            src={NewLogo}
                            variant="square"
                            sx={{ width: 39, height: 90, objectFit: "contain" }}
                        />
                    </Box>
                    <Close className="close-btn" onClick={onClose} />
                </Box>
                <Box className="modalContentDone " >
                    <Box className="main-content loan " >
                        <Box className="votingContent">
                            <Typography component="h1" mt={2} px={4} className="headText" textAlign={"center"}>{offerDetails.status === OFFER_STATUS.PENDING ? "Edit/Cancel Offer for" : "Cancel Offer for"} {offerDetails?.asset_data?.asset_title}.</Typography >

                            <Typography px={4} style={{ fontSize: '13px', fontWeight: "500", textAlign: "center" }}  > {offerDetails.status === OFFER_STATUS.PENDING ? 'Note: Clicking on the "Edit Offer" will allow you to edit & save the changes and clicking on the "Cancel Offer" will permanently remove the offer from the system.' : 'Note: Clicking on the "Cancel Offer" will permanently remove the offer from the system.'}</Typography >

                            <Typography px={4} style={{ fontSize: '13px', fontWeight: "500", textAlign: "center" }}  > {offerDetails.status === OFFER_STATUS.PENDING ? 'Disclaimer:  If the offer amount increases, you must pay RACE tokens, and If reduce the offer amount, you will receive RACE tokens, which are used at the time of investing , When an investor cancels the offer, receive RACE tokens, which are used at the time of investing.' : ''}</Typography >


                            <Box px={5} mt={2} className="EditCancelOfferModal-field">
                                <Grid container spacing={1}>
                                    {
                                        viewTable.map((item, index) => {
                                            return (
                                                <Grid item md={4} sm={6} xs={12} key={`edit-cancel-offer-${index}`}>
                                                    <TextField
                                                        disabled={EditMode === true ? false : true}
                                                        label={item.title}
                                                        type="text"
                                                        placeholder={item.placeholder}
                                                        value={item.value}
                                                        InputProps={{
                                                            inputComponent:
                                                                item.type === 'rate' ? NumericFormatCustomPercent : item.type === 'amount' ? NumericFormatCustomDollar : NumericFormatCustom,
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true, // This prop makes the label shrink when the input has content
                                                            style: { color: '#fff' },
                                                        }}
                                                        style={{ marginRight: "10px", marginTop: '8px' }}
                                                        sx={{ input: { color: " #94A3B8 !important" } }}
                                                        onChange={(e) => handleChange(item.title, e)}
                                                        onKeyDown={(event) => {
                                                            if (item.type === 'duration' && (event?.key === '.')) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>

                            {errorMsg && <Typography px={4} style={{ color: 'red', fontSize: '14px', fontWeight: "500", textAlign: "center" }}>You either exceed or fall short of the limit as per the approved deal terms.</Typography >}
                            {offerDetails.status === OFFER_STATUS.ACCEPTED && <Typography px={4} style={{ color: '#CCCCCC', fontSize: '21px', fontWeight: "600", textAlign: "center" }}>Are you sure you want to cancel?</Typography >}

                            {offerDetails?.asset_data?.investment_type?.investment_name === INVESTMENT_TYPE.LOAN && EditMode ?
                                <Box className='investor-select-currency-type' px={4}>
                                    <Typography className='pay-using font-14'>
                                        Pay using
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="payment-method"
                                            name="payment-method"
                                            defaultValue="USDT"
                                            row
                                            className='currency-options'
                                            value={selectedOption}
                                            onChange={handleOptionChange}
                                        >
                                            {/* <FormControlLabel
                                                value="RACE"
                                                control={<Radio />}
                                                label="RACE"
                                            /> */}
                                            <FormControlLabel
                                                value="USDT"
                                                control={<Radio />}
                                                label="USDT"
                                            />
                                            <FormControlLabel
                                                value="USDC"
                                                control={<Radio />}
                                                label="USDC"
                                            />
                                            <FormControlLabel
                                                value="DAI"
                                                control={<Radio />}
                                                label="DAI"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box> : null
                            }

                            <Box className='vote-btn-box' mt={2} flexDirection={offerDetails.status === OFFER_STATUS.ACCEPTED ? "row-reverse" : 'row'}>
                                {(offerDetails.status !== OFFER_STATUS.EXPIRED && offerDetails.status !== OFFER_STATUS.PAYMENT_TIME_EXPIRED && offerDetails.status !== OFFER_STATUS.HOLD) ?
                                    <Button className={offerDetails.status === OFFER_STATUS.ACCEPTED ? "wallet-button rjct-btn" : "wallet-button appr-btn"} disabled={errorMsg === true ? true : false} onClick={() => { offerDetails.status === OFFER_STATUS.ACCEPTED ? onClose() : EditMode ? handleChangedDataSubmit() : setEditMode(true) }}> <Box className="btn-icon" >{offerDetails.status === OFFER_STATUS.ACCEPTED ? <Close /> : <Done />}{offerDetails.status === OFFER_STATUS.ACCEPTED ? "No" : EditMode ? "Save Changes" : "Edit Offer"}</Box></Button> : ""
                                }
                                <Button className={offerDetails.status === OFFER_STATUS.ACCEPTED ? "wallet-button appr-btn" : "wallet-button rjct-btn"} onClick={() => { saveChanges(offerDetails.asset_id, viewTable, 'Cancelled', offerDetails.id, offerDetails.investment_type_id, offerDetails, offerDetails?.asset_data?.category_id); onClose() }}><Box className="btn-icon" >{offerDetails.status === OFFER_STATUS.ACCEPTED ? <Done /> : <Close />}{offerDetails.status === OFFER_STATUS.ACCEPTED ? "Yes, cancel offer" : "Cancel Offer"}</Box></Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal >
    );
}